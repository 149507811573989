.vulnerability-management-widget {
  margin: 0 auto;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 10px;
  overflow: hidden;
  background: #F9F9F9;
  padding: 20px;
  font-family: "Encode Sans Expanded";
  font-size: 10px;
  font-weight: 300;
  height: 280px;
  letter-spacing: 0em;
  text-align: left;
  width: 615px;
  border-collapse: collapse;
}

.scrollable-table-container {
  overflow-y: auto;
  max-height: 210px; /* Adjust as necessary */
  width: 100%;
}

/* Style the scrollbar (for Webkit browsers) */
.scrollable-table-container::-webkit-scrollbar {
  width: 7px;

}

.scrollable-table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 7px;
}

.scrollable-table-container::-webkit-scrollbar-thumb {
  background: #888; /* Handle color */
  border-radius: 7px;
}

.scrollable-table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}

  .vulnerability-management-widget h2 {
    text-align: left;
    color: #063565;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
  }
  
  .vulnerability-management-widget table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .vulnerability-management-widget th {
    text-align: left;
 
    padding: 10px 20px;
    color: #063565;
    font-weight: 600;
    font-size: 14px;
  }
  
  .vulnerability-management-widget td {
    padding: 10px 20px;
    border-bottom: 1px solid #eaeaea;
    color: #000000;
    font-weight: 300;
    font-size: 12px;
  }
  
  /* Add more styles based on your preferences */
  