.menu-open {
    overflow-x: hidden;
    
  }
  .menu-icon {
    margin-right: 16px; 
    color: #A1A1A1;
    background-color: transparent;
  }
  .hamburger-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: linear-gradient(180deg, rgba(6,53,101,1) 0%, rgba(27,36,50,1) 100%); /* Background color */
      display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

  }
  
  
  .menu-links {
    margin-top: 2rem;
    
  }
  
  .menu-links ul {
    list-style: none;
    text-align: center;
  }
  
  .menu-links ul li {
    margin: 1rem;
  }
  
  .menu-links ul li a {
    font-size: 2rem;
    color: #000;
  }
  
  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
    background-color: white;
  }
  .menumobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
z-index: 999;
}
.menumobileLogo { 
 
        color: #ff9900;
        font-size: 24px;
        font-style: normal;
        font-family: "Encode Sans Expanded";
        font-weight: 700;
        width:130px;
        margin-top:20px;
       


   
     
}
@media screen and (min-width: 1106px) {
 
      .menumobile {
        display: none;
      }
      }
      @media screen and (max-width: 1105px) {
 .mio-feed {
    display: none;
 }
        .menudesktop {
          display: none;
        }
        .menumobile {
            margin-top: 20px;
          }
        .profile-side-profile-pic {
            margin-left: 16px;
            margin-top: 20px;
        }
        .logout-pop-up-container {

right: -320px;
z-index: 9999;
        }
        }
        @media screen and (max-width: 1285px) {
            .profile-side-profile-name-div {
            
            }
        }