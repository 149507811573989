.profile-enterprise-container {
    width: 1600px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .profile-enterprise-frame5210 {
    width: 673px;
    height: auto;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame5204 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 64px;
    flex-direction: column;
  }
  .profile-enterprise-frame5203 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 32px;
    flex-direction: column;
  }
  .profile-enterprise-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 24px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 32px;
    text-decoration: none;
  }
  .profile-enterprise-ellipse1 {
    width: 120px;
    height: 120px;
    position: relative;
    border-color: transparent;
  }
  .profile-enterprise-frame5092 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame35 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame33 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text02 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame31 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text04 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame34 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame3301 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text06 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame311 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text08 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame36 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame3302 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text10 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame312 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text12 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame37 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame3303 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text14 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame313 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text16 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame38 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame3304 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text18 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame314 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text20 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5206 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 64px;
    flex-direction: column;
  }
  .profile-enterprise-frame5205 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-text22 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 20px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 32px;
    text-decoration: none;
    width: 40%;
  }
  .profile-enterprise-frame5095 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
  }
  .profile-enterprise-frame5160 {
    display: flex;
    padding: 24px;
    position: relative;
    box-shadow: 0px 4px 20px 0px rgba(26, 28, 30, 0.10000000149011612) ;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 32px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 1);
  }
  .profile-enterprise-frame5159 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame5158 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-text24 {
    color: rgba(121, 121, 121, 1);
    height: auto;
    font-size: 12px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 4px;
    text-decoration: none;
  }
  .profile-enterprise-frame53 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
  }
  .profile-enterprise-text26 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 20px;
    align-self: auto;
    font-style: SemiBold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    margin-right: 4px;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-text28 {
    color: rgba(121, 121, 121, 1);
    height: auto;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5157 {
    display: flex;
    position: relative;
    align-items: flex-end;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame5156 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 17px;
    flex-direction: column;
  }
  .profile-enterprise-frame5149 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle15 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-frame32 {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame3305 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text30 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 12px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame4 {
    width: 138px;
    display: flex;
    padding: 7px 12px 8px 12px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(241, 241, 241, 1);
  }
  .profile-enterprise-frame371 {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    border-color: transparent;
    justify-content: space-between;
  }
  .profile-enterprise-text32 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .profile-enterprise-vector2 {
    width: 8px;
    height: 4px;
    position: relative;
  }
  .profile-enterprise-frame5150 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle11 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text34 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5151 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle12 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text36 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5152 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle13 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text38 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5153 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle14 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text40 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5154 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
  }
  .profile-enterprise-checkmarkcircle16 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text42 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5155 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
  }
  .profile-enterprise-checkmarkcircle17 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
  }
  .profile-enterprise-text44 {
    color: rgba(26, 28, 30, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame91 {
    width: 240px;
    display: flex;
    padding: 6px 12px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 7px;
    justify-content: center;
    background-color: rgba(255, 153, 0, 1);
  }
  .profile-enterprise-text46 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 12px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 8px;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-vector21 {
    width: 8px;
    height: 4px;
    position: relative;
  }
  .profile-enterprise-frame5207 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    /* margin-bottom: 64px; */
    flex-direction: column;
  }
  .profile-enterprise-frame5097 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 24px;
  }
  .profile-enterprise-text48 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame70 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame321 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame3306 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text50 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame315 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text52 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame3307 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 16px;
    flex-direction: column;
  }
  .profile-enterprise-frame3308 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text54 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame316 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(255, 153, 0, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(255, 246, 232, 1);
  }
  .profile-enterprise-text56 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame341 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame3309 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
  }
  .profile-enterprise-text58 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame317 {
    width: 388px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprise-text60 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5209 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame5098 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 24px;
  }
  .profile-enterprise-text62 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 16px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame5208 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-enterprise-frame5094 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
  .profile-enterprise-text64 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .profile-enterprise-frame318 {
    width: 673px;
    height: 403px;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    flex-direction: column;
    background-color: rgba(251, 251, 251, 1);
  }
  .profile-enterprice-frame {
    display: flex;
    padding: 10px 14px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    margin-bottom: 29px;
    background-color: rgba(255, 153, 0, 1);
  }
  
  .profile-enterprice-text{
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    cursor: pointer;
  }
  .profile-enterprice-container{
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  
  }
  .profile-enterprice-container1{
    width: 211px;
    height: 306px;
    display: flex;
    margin-top: 55px;
    align-items: flex-start;
    justify-content: center;
  }
  .profile-enterprice-container-principale{
    width: 100%;
    height: 70px;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .uploadBackgroundImpresa {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 6rem; 
    height: 6rem;
    -webkit-box-shadow: 5px 8px 30px -10px rgba(189,182,185,1);
-moz-box-shadow: 5px 8px 30px -10px rgba(189,182,185,1);
box-shadow: 5px 8px 30px -10px rgba(189,182,185,1);
cursor: crosshair;
border-radius: 12rem;
object-fit: auto;
}


@media(max-width: 1105px) {
  .profile-enterprice-container {
    overflow: hidden;
  }
  .profile-enterprise-frame5210 {
   justify-content: center;
   align-items: center;

  }
  .profile-enterprise-frame5160 {
    margin-right: 0px;
  }
  .profile-enterprise-container {
    margin-top: -100px;
  }
  .invite-pop-up-frame5193 {
    width: 320px;
  }
  .invite-pop-up-frame31input {
    width: 320px;
  }
  .invite-pop-up-frame5032 {
    max-width: 280px;
    padding: 5px 5px;
    justify-content: space-around;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1105px) {
  .profile-enterprise-frame5205 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    flex-direction: column;
  }
}