.active-user-widget {
    background: #F9F9F9;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    height: 129px;
    width: 201px; /* Adjust as needed */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Encode Sans Expanded";
  }
  
  .widget-header {
    color: #212B36;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
  
  }
  
  .widget-percentage {
    color: #212B36; /* Change as needed */
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }
  
  .widget-chart {
    height: 50px; /* Adjust as needed */
    width: 120px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    /* Placeholder styles for the chart */
 
  }
  
  .widget-users {
    font-size: 12px;
    font-weight: 400;
 
    color: #212B36;
  }
  
  .widget-current-users {
    color:#063565;
   font-weight: 500;
   font-size: 28px;
  }
  
  .widget-max-users {
    color: #777;
  }
  