.sign-up-pop-up-container {
  width: 664px;
  height: 774px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 3%);

  z-index: 1000000;


}

.signUp {
  position: absolute;
  top: 60px;
  right: 315px;
  z-index: 100;
  box-shadow: 5px 5px 5px 5px rgba(0.10000000149011612, 0.10000000149011612, 0.10000000149011612, 0.10000000149011612);
  border-color: rgba(217, 217, 217, 217);
}
