.contatti-integrato-contatti-integrato-cambioPiano {
  width: 10%;
  height: auto;
  display: flex;
  position: relative;
  
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.popupCambioPiano {
    width: 10%;
  height: auto;
  display: flex;
  position: relative;
  
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contatti-integrato-free-demo-div {
  display: none;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.contatti-integrato-text {
  color: rgba(45, 45, 45, 1);
  width: 543px;
  height: auto;
  font-size: 36px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: 46.50334167480469px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}
.contatti-integrato-text01 {
  color: rgba(74, 74, 74, 1);
  width: 461px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 23.215837478637695px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-contact-form {
  width: 972px;
  height: 606px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: transparent;
}
.contatti-integrato-contact-form-c2 {
  margin-top: 4px;
  gap: 24px;
  width: 388px;
  height: 606px;
  display: flex;
  padding: 0;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.contatti-integrato-form-frame3 {
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.contatti-integrato-text02 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-frame31input {
  color: rgba(161, 161, 161, 1);
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  font-size: 16px;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.contatti-integrato-form-frame2 {
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-frame331 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.contatti-integrato-text03 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-frame31input1 {
  color: rgba(0, 0, 0, 1);
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  font-size: 16px;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  border-color: rgba(255, 153, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 246, 232, 1);
}
.contatti-integrato-form-frame1 {
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-frame332 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.contatti-integrato-text04 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-frame31input2 {
  color: rgba(0, 0, 0, 1);
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  font-size: 16px;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.contatti-integrato-form-frame4 {
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-frame333 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.contatti-integrato-text05 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-frame31input3 {
  color: rgba(161, 161, 161, 1);
  width: 388px;
  height: 178px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  font-size: 16px;
  text-align: left;
  align-items: flex-start;
  flex-shrink: 0;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(251, 251, 251, 1);
}
.contatti-integrato-buttonbutton {
  display: flex;
  padding: 15px 38px;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 47px;
  background-color: rgba(255, 153, 0, 1);
}
.contatti-integrato-text06 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  line-height: 30px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.contatti-integrato-contact-form-c1 {
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-free-demo-div1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.contatti-integrato-text07 {
  color: rgb(45, 45, 45);
  width: 543px;
  height: auto;
  font-size: 36px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: 46.5033px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}
.contatti-integrato-text08 {
  color: rgb(74, 74, 74);
  width: 461px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 23.2158px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-contact-info-div {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-contact-info-div-c1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 32px;
  flex-direction: column;
}
.contatti-integrato-text09 {
  color: rgba(255, 153, 0, 1);
  height: auto;
  font-size: 30px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.contatti-integrato-text11 {
  color: rgba(74, 74, 74, 1);
  width: 276px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 23.215837478637695px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.contatti-integrato-contact-info-div-c2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-richieste-amm-div {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.contatti-integrato-text20 {
  color: rgba(74, 74, 74, 1);
  width: 461px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 23.215837478637695px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0px;
  text-decoration: none;
}
.contatti-integrato-group1000001662 {
  width: 147px;
  height: 27.3154296875px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.contatti-integrato-text22 {
  color: rgba(255, 153, 0, 1);
  height: auto;
  position: absolute;
  font-size: 18.666667938232422px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
.contatti-integrato-line2 {
  top: 27.3154296875px;
  left: 0px;
  width: 140px;
  height: 0px;
  position: absolute;
}
.contatti-integrato-tech {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.contatti-integrato-text24 {
  color: rgba(74, 74, 74, 1);
  width: 461px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 23.215837478637695px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0px;
  text-decoration: none;
}
.contatti-integrato-group10000016621 {
  width: 256px;
  height: 27.000011444091797px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.contatti-integrato-text26 {
  left: 6px;
  color: rgba(255, 153, 0, 1);
  height: auto;
  position: absolute;
  font-size: 18.666667938232422px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  font-stretch: normal;
  text-decoration: none;
}
.contatti-integrato-line21 {
  top: 27px;
  left: 0px;
  width: 256px;
  height: 0px;
  position: absolute;
}
@media(max-width: 1200px) {
  .contatti-integrato-free-demo-div {
    display: none;
  }
  .contatti-integrato-text01 {
    color: rgb(74, 74, 74);
    font-size: 16px;
    font-family: "Encode Sans Expanded";
    font-weight: 500;
    line-height: 23.2158px;
    text-decoration: none;
  }
}
@media(max-width: 991px) {
  .contatti-integrato-free-demo-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contatti-integrato-text {
    color: rgb(45, 45, 45);
    font-size: 36px;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: 46.5033px;
    text-decoration: none;
  }
  .contatti-integrato-contact-form {
    align-items: center;
    justify-content: center;
  }
  .contatti-integrato-free-demo-div1 {
    display: none;
  }
  .contatti-integrato-text07 {
    color: rgb(45, 45, 45);
    font-size: 36px;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: 46.5033px;
    text-decoration: none;
  }
  .contatti-integrato-text08 {
    color: rgb(74, 74, 74);
    font-size: 16px;
    font-family: "Encode Sans Expanded";
    font-weight: 500;
    line-height: 23.2158px;
    text-decoration: none;
  }
  .contatti-integrato-contact-info-div {
    width: 300px;
  }
}
@media(max-width: 479px) {
  .contatti-integrato-contatti-integrato {
    width: 95%;
    align-items: center;
    justify-content: center;
  }
  .contatti-integrato-free-demo-div {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .contatti-integrato-text {
    width: auto;
    font-size: 30px;
    text-align: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .contatti-integrato-text01 {
    width: auto;
    text-align: center;
  }
  .contatti-integrato-contact-form {
    width: 90%;
    height: auto;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .contatti-integrato-contact-form-c2 {
    width: 100%;
    padding: var(--dl-space-space-unit);
    align-self: center;
    align-items: flex-start;
    justify-content: center;
  }
  .contatti-integrato-form-frame3 {
    width: 100%;
  }
  .contatti-integrato-frame31input {
    width: 100%;
  }
  .contatti-integrato-form-frame2 {
    width: 100%;
  }
  .contatti-integrato-frame31input1 {
    width: 100%;
  }
  .contatti-integrato-form-frame1 {
    width: 100%;
  }
  .contatti-integrato-frame31input2 {
    width: 100%;
  }
  .contatti-integrato-form-frame4 {
    width: 100%;
  }
  .contatti-integrato-frame31input3 {
    width: 100%;
  }
  .contatti-integrato-container {
    width: 100%;
  }
  .contatti-integrato-contact-form-c1 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .contatti-integrato-contact-info-div {
    width: 80%;
    margin-top: 32px;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: center;
  }
  .contatti-integrato-contact-info-div-c1 {
    width: 100%;
  }
  .contatti-integrato-text11 {
    width: 100%;
  }
  .contatti-integrato-contact-info-div-c2 {
    width: 100%;
  }
  .contatti-integrato-richieste-amm-div {
    width: 100%;
  }
  .contatti-integrato-text20 {
    width: 100%;
  }
  .contatti-integrato-tech {
    width: 100%;
  }
  .contatti-integrato-text24 {
    width: 100%;
  }
}
