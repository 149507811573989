.google-map-container {
    width: 60vw;
    height: 35vw;
   
}

@media screen and (max-width: 768px) {
    .google-map-container {
        width: 95vw;
        height: 100vw;
    }
}