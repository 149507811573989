.group-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
}

.group-modal {
    background-color: #F9F9F9;
    width: 600px; /* Adjust as necessary */
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Encode Sans Expanded";

}

.account-list-widgetSettingsGroupModal {
    font-family: "Encode Sans Expanded";
    background-color: 
    #F9F9F9;
    border-radius: 6px;
   
display: flex;

    overflow-y: scroll;
    margin: 20px auto;
    font-family: "Encode Sans Expanded";
 
    max-height: 744px;
  }
    /* Style the scrollbar (for Webkit browsers) */
.account-list-widgetSettingsGroupModal::-webkit-scrollbar {
    width: 7px;
  
  }
  
  .account-list-widgetSettingsGroupModal::-webkit-scrollbar-track {
    background:   #F9F9F9; /* Track color */
    border-radius: 7px;
  }
  
  .account-list-widgetSettingsGroupModal::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 7px;
  }
  
  .account-list-widgetSettingsGroupModal::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }

.group-modal-header {
    padding: 20px;
    border-bottom: 1px solid #eaeaea;
    color: #063565;
    font-weight: 300;
display: flex;
flex-direction: row;
justify-content: space-between;
}
.LeftAccountGroupModal {
 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

 gap: 5px;
}
.LeftAccountGroupModalc1 {
    background: #FF9900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
}
.LeftAccountGroupModalc2 {
    font-size: 10px;
    font-weight: 400;
}
.group-modal-headerh1 {
    padding: 20px;
 
    color: #063565;
    font-weight: 300;
display: flex;
flex-direction: column;
}
.group-modal-header h2 {
    margin: 0;
    font-size: 21px;
    color: #063565;
    font-weight: 500;
    margin-bottom: 8px;
}

.group-modal-content {
    max-height: 400px; /* Adjust as necessary */
    overflow-y: auto;
}

.group-list {
list-style: none;
padding: 0;
margin: 0;
}

.group-item {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
border-bottom: 1px solid #eaeaea;
}

.group-label {
display: flex;
align-items: center;
width: 100%;
}

.group-checkbox {
margin-right: 10px;
}

.group-name {
flex-grow: 1;
}

.group-member-count {
margin-left: auto;
padding-left: 20px; /* To match spacing from design */
}

.group-modal-footer {
padding: 20px;
display: flex;
justify-content: flex-end;
border-top: 1px solid #eaeaea;
}

.btn-cancelGroupModal {
    padding: 10px 30px !important;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    background: #C7C6C6 !important ;
    color: white !important;
}
.btn-confirmGroupModal {
padding: 10px 30px !important;
margin-left: 10px;
border: none;
cursor: pointer;
border-radius: 6px;
font-size: 16px;
background: #063565 !important ;
color: white !important;

}


  /* Table styling */
  .account-tableSettingsGroupModal {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  
  }
  .account-tableSettingsGroupModal th {
background-color:   #F9F9F9 !important;


  }
  .account-tableSettingsGroupModal thead {



  }
  .buttonAccountListWithDivide {
 
    border-right: 1px solid #0000001F !important;
    border-left: 1px solid #0000001F !important;
    border-radius: 0px !important;
   
  }
  
  .account-tableSettingsGroupModal th, .account-tableSettingsGroupModal td {
    text-align: left;
    padding: 12px;

    
  }
  
  .account-tableSettingsGroupModal th {
    font-weight: 500;
    font-size: 11px;
    color: #063565;
    /* Ensure there's space for the icon */
    padding-left: 15; /* Adjust based on your icon's width */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    cursor: pointer;
  }
  
  .account-tableSettingsGroupModal th:not(:first-child)::before  {
    content: "";
    background-image: url('https://i.ibb.co/JdYgb2B/Group-1000001701.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -3; /* Align the icon to the left of the padding area */
    top: 8; /* Adjust if necessary for alignment */
    width: 25px; /* Icon width */
    height: 25px; /* Icon height */
  }

/* Hover effect */
.account-tableSettingsGroupModal th:not(:first-child):hover {
    background-image: url('/playground_assets/underlineDashboard.svg');
    background-size: cover 1px; /* Stretch to 100% width, 1px height */
    background-repeat: no-repeat;
    background-position: bottom left; /* Align the background to the bottom */
}
  .noiconthAccountListSettings {
 
    background-image: transparent !important;

  }
  
  .account-tableSettingsGroupModal td {
    color: #6C757D;
    font-size: 11px;
  }
  
  .account-tableSettingsGroupModal tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  /* Image styling */
  .account-tableSettingsGroupModal img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  
  /* Checkbox styling */
  .account-tableSettingsGroupModal th:first-child,
  .account-tableSettingsGroupModal td:first-child {
    text-align: center;
    padding-left: 16px;
  }
  
  .account-tableSettingsGroupModal th:first-child {
    padding-right: 0;
  }
  
  .account-tableSettingsGroupModal td:first-child {
    padding-right: 16px;
  }
  
  .account-tableSettingsGroupModal input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px; /* Adjust the margin as needed */
  }
  .noiconthAccountListSettingsGroupModal {
    margin-left: -15px !important;
  }
  /* Sticky header for table */
  .account-tableSettingsGroupModal th {
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  /* Adjustments for spacing and alignment */
  .account-tableSettingsGroupModal th, .account-tableSettingsGroupModal td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .FilterDivAccountList {
display: flex;
align-items: center;
gap: 8px;
width: 100%;
  }
  .filterIconList {
    width: 6px !important;
margin-left: -10px;

  }



  .loading-overlayImportAzure {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-spinnerImportAzure {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-top-color: #000;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .widget-header button {
      padding: 8px;
      margin-left: 4px;
      font-size: 12px;
    }
  
    .account-tableSettingsGroupModal th, .account-tableSettingsGroupModal td {
      padding: 8px;
    }
  
    .account-tableSettingsGroupModal {
      font-size: 14px;
    }
  }
  