.archivio-component-container {
  width: 100%;
margin-top: -224px;
margin-left: 100px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.power-search-containerArchivioDettaglio {
 
  margin-bottom: 10px;
}
.component-head-archivio-frame13-archivio-dettaglio {
  display: none;
}
.bottoneFloatArchivio-archivio-dettaglio {
  display: none;
}
.component-head-archivio-rectangle69Hide {
  display: none;
}
 .component-head-archivio-text07Hide {
  display: none;
 }
 @media screen and (max-width: 1409px) {
  .component-head-archivio-group92-archivio-dettaglio {
    margin-right: -145px;
  
  }}
@media screen and (max-width: 1285px) {
 .archivio-component-container {
  margin-top: -170px;
 
 }
  .searchbararchivio {
    display: none;
  }
  .component-head-archivio-group92 {
margin-right: -100px;
justify-content: space-around
  }
  .component-head-archivio-group92-archivio-dettaglio {
    margin-right: -145px;
justify-content: space-around
  }
  .component-head-archivio-frame13 {
    margin-left: -200px;
  }
  .tutte-le-cartelle-text {
    margin-left: 360px;
  }
  .archivio-cartelle-condivise-archivio-cartelle-condivise {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: auto;
    height: 100%;
    padding: 0;
    position: relative;
    align-self: left;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    justify-content: flex-start;
    justify-items: start; /* Add this line */
    background: #F2FAFF;
    border-radius: 8px;
    margin-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 40px;
  }
  .tutte-le-cartelle-tutte-le-cartelle {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: 827px;
    height: 100%;
    padding: 0;
    position: relative;
    align-self: center;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 32px;
    justify-content: flex-start;
    background-color: transparent;
    margin-top: 32px;
  }
  }
  @media screen and (max-width: 1105px) {
    .archivio-component-container {
     margin-left: 0px;
    
    }
  
    .tutte-le-cartelle-text {
      margin-left: 310px;
    }
  }

  @media screen and (max-width: 1060px) {
 
    .searchbararchivio {
      display: none;
    }
    .component-head-archivio-group92 {
  margin-right: -205px;
  justify-content: space-around
    }
    .component-head-archivio-group92-archivio-dettaglio {
      margin-right: -145px;
      justify-content: space-around
    }
    .component-head-archivio-frame13 {
      margin-left: -410px;
    }
    .tutte-le-cartelle-text {
      margin-left: 420px;
    }
    .archivio-cartelle-condivise-archivio-cartelle-condivise {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      width: auto;
      height: 100%;
      padding: 0;
      position: relative;
      align-self: left;
      box-sizing: border-box;
      align-items: flex-start;
      flex-shrink: 1;
      border-color: transparent;
      border-style: none;
      border-width: 0;
      margin-right: 0;
      border-radius: 0px 0px 0px 0px;
      justify-content: flex-start;
      justify-items: start; /* Add this line */
      background: #F2FAFF;
      border-radius: 8px;
      margin-top: 32px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-top: 40px;
    }
    .tutte-le-cartelle-tutte-le-cartelle {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      width: 615px;
      height: 100%;
      padding: 0;
      position: relative;
      align-self: center;
      box-sizing: border-box;
      align-items: flex-start;
      flex-shrink: 1;
      border-color: transparent;
      border-style: none;
      border-width: 0;
      margin-right: 0;
      border-radius: 0px 0px 0px 0px;
      margin-bottom: 32px;
      justify-content: flex-start;
      background-color: transparent;
      margin-top: 32px;
    }
    } 

    @media screen and (max-width: 710px) {
 .archivio-component-container {
  overflow: hidden;
 }
 .tuttecartcolumn {

  
}
    .component-head-archivio-text {
      
     margin-left: 160px;
    }
    .component-head-archivio-textArchivio {
      margin-top: 80px;
      margin-left: 160px;
    }
    .component-head-archivio-text02 {
     
      margin-left: 160px;
    }
    .component-head-archivio-text02Archivio {
     margin-top: 80px;
      margin-left: 160px;
    }
    .component-head-archivio-frame13 {
      display: none;
    }
    .bottoneFloatArchivio {
      display: flex;
    }
    .archivio-component-container {
      margin-top: -250px;
    }
    .component-head-archivio-rectangle69 {
      left: 137px;
      width: 400px;
    }
    .component-head-archivio-text07 {
      left: 147px;
    }
    .component-head-archivio-group92 {

    }
    .component-head-archivio-group92-archivio-dettaglio {
margin-left: 0px;
margin-right: -100px;
    }
    .component-head-archivio-container {

    }
    .archivio-cartelle-condivise-rectangle6 {
      width: 163px;
    }
    .archivio-cartelle-condivise-text04 {
      left: 99.7px;
    }
    .archivio-cartelle-condivise-dots1 {
      left: 137px;
      top: 24px;
    }
    .tutte-le-cartelle-group87 {
      width: 163px;
      height: 163px;
    }
    .tutte-le-cartelle-group86 {
      width: 163px;
    }
    .archivio-cartelle-condivise-archivio-cartelle-condivise {
      grid-template-columns: repeat(2, 1fr);
      padding-bottom: 40px;
    }
    .tutte-le-cartelle-text {
      margin-left: 560px;
      width: 300px;
    }
    .tutte-le-cartelle-tutte-le-cartelle {
      grid-template-columns: repeat(2, 1fr);
      width: 330px;
   
    }
    .tutte-le-cartelle-text02 {
      width: 146px;
    }
    .inputDisabled {
      max-width: 130px;
     
    }
    .rename {
      width: 67px;
    
    } 
    .close1 {
     right: 45px !important;
    }
    .tutte-le-cartelle-rectangle6 {
      width: 163px;
    }
 .tutte-le-cartelle-dots1 {
  top: 10px;
  left: 116px;
 }
 .tutte-le-cartelle-folder-blank1 {
  top: 10px;
  right: 15px;
 }
 .archivio-cartelle-condivise-folder-blank1 {
  top: 45px;
  left: 60px;
 }
  }

  @media screen and (max-width: 515px) {
 
    .component-head-archivio-rectangle69 {
      left: 137px;
      width: 35%;
       }
       .component-head-archivio-text07 {
         left: 147px;
       }
       .component-head-archivio-group92-archivio-dettaglio {
        margin-right: -175px;
       }
   
  }
  @media screen and (max-width: 413px) {
 
    .component-head-archivio-rectangle69 {
      left: 162px;
      width: 300px;
       }
       .component-head-archivio-text07 {
         left: 197px;
       }
       
  }
  @media screen and (max-width: 370px) {
 
    .archivio-cartelle-condivise-archivio-cartelle-condivise {
      grid-template-columns: repeat(1, 1fr);
   
    }
    .tutte-le-cartelle-tutte-le-cartelle {
      grid-template-columns: repeat(1, 1fr);
   
    }
    .tutte-le-cartelle-frame30 {
      justify-content: center;
      align-items: center;
    }
    .tutte-le-cartelle-text {
      margin-left: 640px;
    }
  }
