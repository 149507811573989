.sign-up-pop-up-c1-container {
  width: 563px;
  height: 120px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-pop-up-c1-frame125 {
  width: 563px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.sign-up-pop-up-c1-frame5142 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.sign-up-pop-up-c1-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 23px;
  text-decoration: none;
}
.sign-up-pop-up-c1-group124 {
  width: 172px;
  height: 36.9375px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
}
.sign-up-pop-up-c1-group123 {
  width: 172px;
  height: 36.9375px;
  display: flex;
  padding: 0;
  align-self: auto;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 100px;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 245, 230, 1);
}
.sign-up-pop-up-c1-group122 {
  width: 86px;
  height: 36.9375px;
  display: flex;
  padding: 0;
  align-self: auto;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 100px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 153, 0, 1);
}
.sign-up-pop-up-c1-text5 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  font-stretch: normal;
  text-decoration: none;
  cursor: pointer;
}
.sign-up-pop-up-c1-text7 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
  cursor: pointer;

}
.sign-up-pop-up-c1-root-class-name {
  margin-bottom: 14px;
}
