.news-search-result-list-container {
  width: 100%;
  height: 222px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.news-search-result-list-news-maindiv {
  flex: 0 0 auto;
  width: 100%;
  height: 222px;
  display: flex;
  align-items: center;
  border-color: #DADADA;
  border-style: solid;
margin-left:440px ;
  border-radius: 7px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  padding-top: 20px;
}
.news-search-result-list-image-news-result {
  /*flex: 0 0 auto;*/
  /*width: 223px;*/
  /*height: 159px;*/
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*margin-left: var(--dl-space-space-oneandhalfunits);*/
  /*border-color: #dadadada;*/
  /*border-style: solid;*/
  /*border-width: 1px;*/
  /*border-radius: 7px;*/
  /*flex-direction: column;*/
  /*justify-content: flex-end;*/
  /*background-image: url("/playground_assets/logoarancio.png");*/
  /*background-size: contain;*/
  /*background-repeat: no-repeat;*/
}
.news-search-result-loader-container{
  width: 223px;
  height: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-top: 50%;
}

.news-search-result-list-image-result {
  flex: 0 0 auto;
  width: 223px;
  height: 159px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #dadadada;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-end;
  object-fit: cover;
}
.news-search-result-list-image {
  width: 126px;
  object-fit: cover;
  margin-left: 15px;
  margin-top: -20px;
}
.news-search-result-list-title-link-description-news {
  flex: 0 0 auto;
  width: 350px;
  height: 70px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.data-wallet-card {
  flex: 0 0 auto;
  width: 500px;
  height: 70px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.news-search-result-list-link-news {
  font-size: 13px;
  font-family: "Encode Sans Expanded";
}
.news-search-result-list-link-news2 {
  font-size: 11px;
  font-family: "Encode Sans Expanded";
  margin-top: 8px;
}
@media screen and (max-width: 702px) {
  .news-search-result-list-link-news2 {

    margin-top: 0px;
  }
  .data-wallet-enterprise-results-text04 {
    margin-left: 30px;
  }
  .data-wallet-enterprise-header-rectangle890 {
    margin-top: 0px !important;
  }
}
.news-search-result-list-title-news {

  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 22px;
  color: blue;
}
.data-wallet-title {

  font-size: 15px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: 22px;
  color: rgb(0, 0, 0);
  white-space: nowrap;

}
.news-search-result-list-description-news {
  color: #000000;
  font-size: 15px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  line-height: 19px;
  width: 346px
}
.news-search-result-list-share-div {
  flex: 0 0 auto;
  width: 50px;
  /*height: 174px;*/
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-search-result-list-share-icon {
  width: 24px;
  align-self: flex-end;
  object-fit: cover;
  margin-right: 4px;
}

.aziende-card{
  font-size: 16px;
  flex: 1;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.aziende-title {
  color:blue;

}
.power-search-container-containerAziende {
  width: 100%;
  display: flex;
  overflow-y: scroll !important;
  overflow-x: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.aziende-width {
width: 200px;
margin-left: 60px;
margin-top: -50px;
gap: 60px;

}
@media screen and (max-width: 1105px) {
  .aziende-width {
    width: 200px;
    margin-left: 0px;
    margin-top: -10px;
    gap: 6px;
    }
    .aziende-search-tabs-filters-div {
      flex: 0 0 auto;
      width: 433px;
      height: var(--dl-size-size-medium);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative !important;
      z-index: 2;
      top: -220px !important;
      left: 20px !important;
      
    }
    
}

.aziende-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 80px;
  left: 190px;
}

@media(max-width: 826px) {
.aziende-card {
  flex-direction: column;
  height: 108px;
  align-items: flex-start;
}
.aziende-city{
  text-align: left !important;
  width: 100% !important;
  justify-content: flex-start !important;
}
.aziende-width {
  width: 639px !important;
}
}
@media(max-width: 665px) {

  .aziende-width {
    width: 539px !important;
  }
  }
  @media(max-width: 570px) {

    .aziende-width {
      width: 339px !important;
    }
    }