 .PrivacyWidgetsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-bottom: 45px;
 } 
 .PrivacyWidgetsContainerc2 {
   display: flex;
   flex-direction: row;
   gap: 20px;

 }
 .PrivacyWidgetsContainerc3 {
   display: flex;
   flex-direction: row;
   gap: 20px;

 }

 .PrivacyWidgetsContainerc3c {
   display: flex;
   flex-direction: column;
   gap: 20px;
margin-top: -49px;
 }
 .PrivacyWidgetsContainerc3c1 {
   display: flex;
   flex-direction: row;
   gap: 20px;

 }
 