.pagination {
    display: inline-block;
    margin: 0px 0;
  
}
.footersearch {
    display: flex; 
    flex-direction: column;
    width: 100%;
    margin-left: 460px;
}
@media screen and (max-width: 1007px) {
    .footersearch {
        display: flex; 
        flex-direction: column;
        width: 100%;
        margin-left: 20%;
    }
}
@media screen and (max-width: 707px) {
    .footersearch {
        display: flex; 
        flex-direction: column;
        width: 100%;
        margin-left: 0px;
    }
}
.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.pagination a {
    color: black;
    float: left;
    text-decoration: none;
    height: 35px;
    width: 35px;
    margin: 20 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid transparent;
    font-size: 15px;
    font-family: "Encode Sans Expanded";
}

.pagination a:first-child, a:last-child {
    color: rgb(253,153,39);
    font-weight: bold;
    font-size: 15px;

}

.pagination a.active {
    background-color: rgb(253,153,39);
    color: white;
    border-radius: 5px;
}

.pagination a:hover:not(.active) {
    background-color: #fff;
    border: 1px solid rgb(253,153,39);
    border-radius: 5px;
}
