.logout-pop-up-container {
    width: auto;
    min-height: 230px;
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 50px;
    right: 10px; 
  z-index: 2000;
 
  }
  .logout-pop-up-logout {
    width: 100%;
    min-height: 230px;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);

    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
  
  }

  .logout-pop-up-rectangle67 {
    top: 45px;
    left: 24px;
    width: 16px;
    height: 16px;
    border-color: rgba(161, 161, 161, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
  }
  .logout-pop-up-frame5125 {
    width: 338px;
    display: flex;
    padding: 7px 27px;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    flex-direction: column;
    margin-top: 20px;
  

  }

  .profile-side-username-container {
    display: flex;
    flex-direction: row;
justify-content: center !important;
text-align: center !important;
align-items: center !important;
margin-right: -44;
gap: 10px;
  }
  .logout-pop-up-frame5124 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    word-wrap: break-word; /* Add this line */

    
  }
  .linelogout {
 
width: 80%;
align-items: center;
align-self: center;
height: 0px;

margin-top: 20px;
margin-bottom: 20px ;
border: 1px solid #DADADA;
  }
  .logout-pop-up-rectangle68 {
    width: 10px;
    display: none;
    height: 10px;
    position: relative;
    border-color: transparent;
    margin-right: 18px;
    border-radius: 50px;
    background-image: url('https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d715cf8a-99be-4b4d-8894-0bcff53b9223/d6ee7ca9-7025-492b-85e2-e9f0fa56f62c?org_if_sml=1123');
  }
  .logout-pop-up-ellipse1 {
    width: 44px;
    height: 44px;
    position: relative;
    border-color: rgba(182, 182, 182, 1);
    border-style: solid;
    border-width: 1px;
    margin-right: 18px;
    border-radius: 100px;
  }
  .logout-pop-up-text {
    color: #1E2F6D;
    max-width: 100%;
    word-break: break-word;
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .logout-pop-up-frame5123 {
    top: 90px;
    left: 24px;
    width: 214px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: transparent;
  }
  .logout-pop-up-rectangle69 {
    width: 16px;
    height: 16px;
    position: relative;
    border-color: rgba(161, 161, 161, 1);
    border-style: solid;
    border-width: 1px;
    margin-right: 16px;
    border-radius: 50px;
  }
  .logout-pop-up-ellipse3 {
    width: 44px;
    height: 44px;
    position: relative;
    border-color: rgba(182, 182, 182, 1);
    border-style: solid;
    border-width: 1px;
    margin-right: 16px;
  }
  .logout-pop-up-frame5109 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .logout-pop-up-text02 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0px;
    text-decoration: none;
  }
  .logout-pop-up-text04 {
    color: rgba(161, 161, 161, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .logout-pop-up-text06 {
    top: 18px;
    left: 374px;
    color: rgba(0, 0, 0, 1);
    height: auto;
    position: absolute;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .logout-pop-up-text08 {
    top: 78px;
    left: 374px;
    color: rgba(0, 0, 0, 1);
    height: auto;
    position: absolute;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .marginBottomW {
    margin-bottom: 10px;

  }
  .logout-pop-up-frame5126 {
    margin-top: 10px;
    margin-left: 30px;
    width: 200px;
    display: flex;
    align-items: center;
    border-color: transparent;
    cursor: pointer;
   
  }
  .logout-pop-up-exitlogoutcopy1 {
    width: 24px;
    height: 24px;
    position: relative;
    margin-right: 8px;
    
  }
  .iconaccountchange {
    width: 24px;
  height: auto;
  }
  .logout-pop-up-text10 { 
    color: #1E2F6D;
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
    gap: 10px;
    white-space:nowrap;
  }
