.toggleComponent-popup {
    
    border-radius: 8px;
    padding: 40 40 40 15;
    border-radius: 8px;
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Encode Sans Expanded";
    width: 287px;
height: 214px;
justify-content: flex-start;

  }
 
  .toggleComponent-header h2 {
    color: #063565;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    
  }
  
  .toggleComponent-setting {
    display: flex;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    flex-direction: column;
  }
  
  .toggleComponent-text {
    color: #787777;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
white-space: nowrap;

  }
  
  .toggleComponent-toggle {
    display: flex;
    align-items: center;
    margin-top: 38px;
    margin-left: 30px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
    margin-top: 10px;
    
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #063565;
  }
  
  input:checked + .slider:before {
    transform: translateX(19px);
  }
  
  .toggleComponent-status {
    margin-left: 10px;
    color: #2B3674;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    user-select: none;
  }
  