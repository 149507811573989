.extension-block-notification {
    background-color: #F9F9F9;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    width: 100%; /* Full width to accommodate content */
    max-width: 353px; /* Maximum width of the container */
    margin: auto; /* Center the container */
    font-family: "Encode Sans Expanded";
    height: 355px;
  }
  
  .stat-item {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; /* Needed for absolute positioning of the chart */
  }
  
  .stat-title {
    color: #787777;
    font-size: 14px;
    margin-bottom: 5px;
    flex: 1; /* Allow the title to grow */
    font-weight: 500;
  }
  
  .stat-count {
    color: #063565;
    font-size: 28px;
    margin-bottom: 5px;
    flex: 1; /* Allow the title to grow */
    font-weight: 700;
  }
  
  .stat-change {
    color: #787777;
    font-size: 14px;
    margin-bottom: 5px;
    flex: 1; /* Allow the title to grow */
    font-weight: 300;
  }
  .stat-item.last-item {
    border-bottom: none;
  }
  .stat-chart {
    width: 80px; /* Width of the chart */
    height: 40px; /* Height of the chart */
    position: absolute; /* Absolute position the chart within the stat-item */
    right: 10px; /* Position from the right edge */
    top: 50%; /* Position from the top */
    transform: translateY(-50%); /* Center vertically */
  }
  
  /* Specific adjustments for the chart canvas */
  .stat-chart canvas {
    max-height: 100% !important; /* Ensure it doesn't overflow the container */
  }
  