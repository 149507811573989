.bottone-generico1-container {
  flex: 0 0 auto;
  width: 110px;
  height: 38px;
  display: flex;
  position: relative;
  margin-top: 50px;
  margin-right: 70.5px;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: #FF9900;
}
.bottone-generico1-container1 {
  flex: 0 0 auto;
  width: 124px;
  height: 38px;
  display: flex;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 30px;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: #FF9900;
  cursor: pointer;
}
.bottone-generico1-testobottonegenerico {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: "Encode Sans Expanded";
  cursor: pointer;
}
.bottone-generico1-container1  {
  margin-top: 0px;
  margin-right: 30px !important;
  margin-left: -16px;
  width: 100px;
   }
@media screen and (max-width: 1105px) {


  .bottone-generico1-container1  {
 margin-top: 0px;
 margin-right: 0px;
 margin-left: 16px;
 width: 50px;
  }
}