.search-bar1-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.search-bar1-container {
  flex: 0 0 auto;
  width: 671px;
  height: 50px;
  display: flex;
  position: relative;
  margin-top: 14px;
  align-items: flex-start;
  border-color: #dadada;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  justify-content: flex-start;
  background-color: #ffffff;
}

.search-bar1-search-bar-main-div {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.search-bar1-search-div {
  flex: 0 0 auto;
  width: 10%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  justify-content: flex-start;
}

.search-bar1-searc-text {
  color: rgb(161, 161, 161);
  text-align: center;
  font-family: "Encode Sans Expanded";
}

.search-bar1-delete-search-div {
  flex: 0 0 auto;
  width: 10%;
  height: 50px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.search-bar1-delete-icon {
  width: 24px;
  align-self: center;
  object-fit: cover;
  cursor: pointer;
}

.search-bar1-search-icon-div {
  flex: 0 0 auto;
  width: 50%;
  height: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.search-bar1-search-icon {
  width: 12-38;
  align-self: center;
  object-fit: cover;
}

.search-bar1-root-class-name {
  margin-top: 0px;
}

.search-bar1-root-class-name1 {
  margin-top: 66px;
}

.search-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  width: 840% !important;
}

.search-input:focus {
  outline: none;
}

.weagle-ai-link {
  text-decoration: none;
  margin-left: 20px;
  width: 5px;
}

.weagle-ai-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #1E2F6D;
white-space: nowrap;
width: 5px;
font-weight: 500;
}



.weagle-ai-icon {
  width: 23px;
  margin-right: 8px;
  margin-left: -15px;
}
.weagleaitext {
  font-size: 13px;
  color: #1E2F6D;
}
@media (max-width: 1510px) {
  .profile-side-profile-name-div {
 
  }
  .weagle-ai-link {
    
  }
  .power-search-container2 {
margin-left: -0px;
   
  }
}
@media(max-width: 565px) {
  .search-input {
    width: 650% !important;
  }
}

@media(max-width: 767px) {

  .weagle-ai-link {

    display: none;
  }
  

  

  .search-bar1-container {
    width: 30rem;
  }
  
  .search-bar1-container-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .weagle-ai-link {
    margin-left: 0;
    margin-top: 10px;
  }
  
}

@media (max-width: 1105px) {
  .search-bar1-container {
    width: 90vw
  }
  .search-bar1-delete-search-div {
    width: 60px;
  }
  .search-con-prova-container1 {
    display: none !important;;
  }  
  .profile-side-profile-name-div {
    display: none !important;
  }
  .power-search-container1 {
    display: none !important;
  }
}