.language-filter-dropdown-search {
  width: 300px;
  overflow: hidden;
  max-width: 262px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  box-sizing: border-box;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 1);
  left: 0;
  z-index: 1;
  cursor: pointer;
  padding: 15px;
}
.language-filter-input-paese {
  top: 18px;
  left: 16px;
  width: 229px;
  height: 46px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 7px;
  margin-bottom: 0;
}
.language-filter-cerca-un-paese {
  top: 31px;
  left: 28px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.language-filter {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-top: 10px;
}

.active {
  color: orange;
  font-weight: bold;
}
.language-filter-text03 {
  top: 82px;
  left: 27px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.language-filter-text05 {
  top: 150px;
  left: 27px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.language-filter-text07 {
  top: 218px;
  left: 27px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.language-filter-text09 {
  top: 184px;
  left: 27px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.language-filter-text11 {
  top: 252px;
  left: 27px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.filtro-langauge-cerca-key-word {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 10px;
  text-decoration: none;
  padding: 15px 20px;
  box-sizing: border-box;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  width: 100%;
  border-radius: 10px;
}

.filtro-language-root-class-name {
  top: 40px;
  position: absolute;
  z-index: 100;
  left: 200px;
}
