/* dashboard.css */

.dashboardOverview {
    display: flex;
    min-height: 100vh; /* Full height of the viewport */
    overflow: hidden;
    max-height: 100vh; /* Full height of the viewport */
}



.overviewWidgetsView {
    flex-grow: 1; /* Takes up the remaining space */
    display: flex;
    justify-content: center; /* Center the widgets horizontally */
    align-items: flex-start; /* Center the widgets vertically */
  overflow-y: scroll;
   
}

.accountListwWidgetsView {
    flex-grow: 1; /* Takes up the remaining space */
    display: flex;
    justify-content: center; /* Center the widgets horizontally */
    align-items: flex-start; /* Center the widgets vertically */
    overflow-y: scroll;
}
  /* Style the scrollbar (for Webkit browsers) */
  .accountListwWidgetsView::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  
  }
  
  .accountListwWidgetsView::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 7px;
  }
  
  .accountListwWidgetsView::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(6,53,101,1) 0%, rgba(27,36,50,1) 100%);
    border-radius: 7px;
  }
  
  .accountListwWidgetsView::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }
/* Add additional styles for widgets and sidebar content as needed */
.mobileIcon {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

}

.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
  pointer-events: auto;
}
.integrationsView {
  flex-grow: 1; /* Takes up the remaining space */
  display: flex;
  justify-content: center; /* Center the widgets horizontally */
  align-items: center; /* Center the widgets vertically */
}

@media screen and (max-width: 1315px) {
  .mobileIcon {
    display: block;
  }

  .sidebar.hidden {
    display: none;
  }
}
