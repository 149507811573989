.search-engine-selection-container {
  flex: 0 0 auto;
  width: 672px;
  height: 19px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
margin-bottom: 15px;

}
.search-engine-selection-google {
  flex: 0 0 auto;
  width: 94px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.search-engine-selection-checkbox {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.search-engine-selection-image {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-d-d-g {
  flex: 0 0 auto;
  width: 134px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.search-engine-selection-checkbox1 {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.search-engine-selection-image1 {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text1 {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-bing {
  flex: 0 0 auto;
  width: 71.9px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.search-engine-selection-checkbox2 {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  display: none;
}
.search-engine-selection-image2 {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text2 {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-yahoo {
  flex: 0 0 auto;
  width: 86.27px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.search-engine-selection-checkbox3 {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.search-engine-selection-image3 {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text3 {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-baidu {
  flex: 0 0 auto;
  width: 82.69px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.search-engine-selection-checkbox4 {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.search-engine-selection-image4 {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text4 {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-startpages {
  flex: 0 0 auto;
  width: 116.2px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.search-engine-selection-checkbox5 {
  border-color: #a1a1a1;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.search-engine-selection-image5 {
  width: 16px;
  object-fit: cover;
  margin-left: 8px;
}
.search-engine-selection-text5 {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
  margin-left: 4px;
}
.search-engine-selection-root-class-name {
  margin-top: 19px;
  margin-left: 57px;
}
