.news-filter-group74 {
  width: 800px;
  height: 20px;
  visibility: hidden;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 90%;
}
.news-filter-group74Hide {
 
  height: 20px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 30%;
}

.news-filter-frame4 {
  top: 0px;
  left: 0px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
  cursor: pointer;
}

.news-filter-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-filter-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
.news-filter-text02 {
  top: 10px;
  left: 603px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.news-filter-text02-2 {
  top: 10px;
  left: 479px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.news-filter-frame1 {
  cursor: pointer;
  top: 0px;
  left: 118px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.news-filter-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-filter-vector21 {
  width: 8px;
  height: 4px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
.news-filter-frame2 {
  top: 0px;
  left: 203px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
  cursor: pointer;
}

.news-filter-text06 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-filter-vector22 {
  width: 8px;
  height: 4px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
.news-filter-text08 {
  top: 7.5px;
  left: 308px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.news-filter-group23 {
  top: 9.6435546875px;
  left: 429px;
  width: 29.425737380981445px;
  height: 14.712846755981445px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 0px 0px 0px 0px;
}
.news-filter-rectangle61 {
  top: 0px;
  left: 0px;
  width: 29px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  border-radius: 20px;
  margin-bottom: 0;
}
.news-filter-ellipse2 {
  top: 0px;
  left: 14.712890625px;
  width: 15px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 0;
}
