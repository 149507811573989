.TimeLimitationChangeDiv {
    display: flex;

    gap: 20px;
    margin-left: 10px;
}
.TimeLimitationChangeDiv span {
    color: #063565;
    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-right: 10px;
}
.TimeLimitationChangeDiv select {
font-size: 12px !important;
color: #063565;
font-family: "Encode Sans Expanded";
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}