.search-con-prova-container {
  width: 100%;
  display: flex;
overflow-x: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.search-con-prova-container-principale {
  width: 100%;
  height: 287px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}


.newmenucompleto {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newmenucompleto > * {
  margin: 30px 0px;
}
.search-con-prova-container1 {
  width: 211px;
  margin-left: -30px;
  height: 306px;
  display: flex;
  margin-top: 55px;
  align-items: flex-start;
  justify-content: center;
}

.search-con-prova-container2 {
  flex: 0 0 auto;
  width: 145px;
  height: 38px;
  display: flex;
  margin-top: 73px;
  align-items: center;
  margin-right: 70.5px;
  border-radius: 7px;
  justify-content: center;
  background-color: #FF9900;
  cursor: pointer;
}
.search-con-prova-text {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: "encode sans expanded";
}
.search-con-prova-naviga-trapiu-div {
  width: 784px;
  height: 198px;
  display: flex;
  margin-top: 4.8vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.search-con-prova-naviga-trapiu-div-c1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.search-con-prova-text03 {
  color: #1E2F6D;
  width: 359px;
  font-size: 22px;
  text-align: center;
  font-weight: 300;
  font-family: "Encode Sans Expanded";
  line-height: 40px;
  margin-top: -30px;
  display: flex;
  justify-content: center;
}
.search-con-prova-demoresearchp {
  color: #a1a1a1;
  font-size: 12px;
  text-align: center;
  font-family: "encode sans expanded";
}
.search-con-prova-iconsdiv {
  flex: 0 0 auto;
  width: 726px;
  height: 10px;
  display: flex;
  margin-top: 65px;
  align-items: center;
  justify-content: center;
}
.search-con-prova-feature1 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  align-items: center;
  margin-right: 32px;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}
.search-con-prova-image {
  width: 32px;
  object-fit: cover;
}
.search-con-prova-text07 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
  font-family: "Encode Sans Expanded";
}
.search-con-prova-feature2 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.search-con-prova-image1 {
  width: 32px;
  object-fit: cover;
}
.search-con-prova-text08 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
  font-family: "Encode Sans Expanded";
}
.search-con-prova-feature3 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.search-con-prova-image2 {
  width: 32px;
  object-fit: cover;
}
.search-con-prova-text09 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  font-family: "Encode Sans Expanded";
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
}
.signUp {

  position: absolute;
  top: 60px;
  right: 315px;
  z-index: 100;
  box-shadow: 5px 5px 5px 5px rgba(0.10000000149011612, 0.10000000149011612, 0.10000000149011612, 0.10000000149011612);
  border-color: rgba(217, 217, 217, 217);
}
.handelInput{
  width: 100%;
  background-color: transparent;
  outline: 0;
}
@media screen and (max-width: 1024px) {
.search-con-prova-text03 {

font-size: 30px;

}

}
@media screen and (max-width: 734px) {
.search-con-prova-iconsdiv {
  flex-direction: column;
  margin-top:180px;
  margin-bottom: 200px;
}
  .search-con-prova-feature1 {
    margin-right: 0px;
  }
  .search-con-prova-feature2 {
    margin-right: 0px;
  }
  .search-con-prova-feature3 {
    margin-left: 0px;
  }
  }
  @media screen and (max-width: 769px) {
    .search-con-prova-iconsdiv {
      flex-direction: column;
      margin-top:180px;
      margin-bottom: 200px;
    }
      .search-con-prova-feature1 {
        margin-right: 0px;
      }
      .search-con-prova-feature2 {
        margin-right: 0px;
      }
      .search-con-prova-feature3 {
        margin-left: 0px;
      }
  
      }
@media screen and (max-width: 500px) {
  .search-con-prova-naviga-trapiu-div {
   width: 90%;
   margin-top: -100px;
  }
  
  .search-con-prova-text03 {

    font-size: 23px;
 
    }
  
  }
  @media screen and (max-width: 1105px) {

 .motorepredefdiv {
  display: none;
 }
 .buttonSegnala {
  display: none;
 }
    .sign-up-pop-up-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      z-index: 1000000;
      box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 10%);
      border-color: rgba(217, 217, 217, 217);
      overflow-x: hidden !important;
      overflow-y: scroll !important;
    }
    .sign-up-pop-up-container::-webkit-scrollbar {
      display: none;
    }
    .sign-up-pop-up-c1-container {
      width: auto;
    }
    .sign-up-pop-up-c1-container {width: auto;}
    .sign-up-pop-up-c1-text {
      width: 300px;
      margin-bottom: 5px;
    }
    .user-sign-up-frame31 {
      width: 300px;
    }
    .user-sign-up-frame311 {
      width: 300px;
    }
    .user-sign-up-frame32 {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-height: 200px) {
    .search-con-prova-naviga-trapiu-div {

      margin-top: 0px;
    
    }
    
    }

