.statisticsusagecontainer {
  display: flex;
  flex-direction: column;
  width: 553px; /* Adjust the width as needed */
  background: #FBFAFB;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Encode Sans Expanded", sans-serif;
  border-radius: 6px;
  height: 515px;
  
  overflow: hidden; /* Ensures the shadow doesn't get cut off */
}

.statisticsusageTitleDateDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
margin-top: 10px;
  
}
.statisticsusageTitleDateDiv2 {
  display: flex;
  justify-content: flex-end;
}
.statisticsusagedropdownsdiv {
  display: flex;


}
.statisticsusageTitle {
  color: #063565;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
}

.statisticsusageGraphDetailtext {
  color: #063565;
  font-size: 10px;
  font-weight: 400;
  margin: 4px 19px; /* Adjust margin to align with your design */
}

.numbersearchconducteddiv {
  display: flex;
  justify-content: space-around; /* This will space out your charts evenly */
  margin: 20px;
  height: 104px; /* Adjust as needed */
}
.doublegraphdiv {
  display: flex;
 justify-content: space-between;
  margin: 20px;
align-items: flex-end;
  width: 100%;

  
}
.doubledivtitledivt {
  display: flex;
  justify-content: flex-start;
  gap: 45px;
 
}
.doublegraphdivc1 {
  display: flex;
 

margin-left: -20px;
  width: 200px !important;
  height: 190px;

}
.doublegraphdivc3 {
  display: flex;
 margin-right: 30px;
  width: 60%;
  height: 160px;

}
/* Styling for the individual charts within doublegraphdiv */
.chart-container {
  flex: 1; /* This will make each chart take up equal space */
  padding: 0 10px; /* Gives some space between the charts */
}

/* Specific styles for the Doughnut and Line charts if needed */
.doughnut-chart-container {
  width: 50%; /* Adjust the width as needed */
}

.line-chart-container {
  width: 50%; /* Adjust the width as needed */
}

/* You might want to add media queries if you're dealing with responsiveness */
