.adblockTrackingcontainer {
    display: flex;
    flex-direction: column;
    width: 447px;
    height: 193px;
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "Encode Sans Expanded", sans-serif;
    border-radius: 6px;

    overflow: hidden; /* Ensures the shadow doesn't get cut off */
  }
  
  .adblockTrackingTitleDateDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  margin-top: 10px;
    
  }
  .adblockTrackingTitleDateDiv2 {
    display: flex;
    justify-content: flex-end;
  }
  .adblockTrackingTitle {
    color: #063565;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
  }
  
  .adblockTrackingGraphDetailtext {
    color: #063565;
    font-size: 10px;
    font-weight: 400;
    margin: 4px 19px; /* Adjust margin to align with your design */
  }
  
  
  .doublegraphdivadblockTracking {
    display: flex;

  margin-top: 10px;
    width: 100%;
    height: 100%;
    justify-content: space-around;


  }
.lineBorderadReports {
    width: 1px;

background: #EAEAEA;
box-shadow: 0px 1px 1px 0px #00000040;
height: 126px;
margin-top: 10px;
flex-shrink: 0;
}
  .doubledivtitledivtadblockTracking {
    display: flex;
    justify-content: space-around;
  text-align: center;
  align-items: center;
   border: 1px solid #EAEAEA;
   
  }
  .doublegraphdivc1adblockTracking {
    display: flex;
   
  flex-direction: column;
  justify-content: center;
align-items: center;

  
  
  }
  .doublegraphdivc2adblockTracking {
    display: flex;
   
  flex-direction: column;
  justify-content: center;
align-items: center;
  }

  .numberadblockTrackingchart {
    color: #063565;
font-family: "Encode Sans Expanded";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 48px; /* 171.429% */
  }
  .percentageadblockTrackingchart {
    color: #787777;
font-family: "Encode Sans Expanded";
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 22px; /* 157.143% */
  }
  /* You might want to add media queries if you're dealing with responsiveness */
  