.archivio-cartelle-condivise-archivio-cartelle-condivise {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: auto;
  height: 100%;
  padding: 0;
  position: relative;
  align-self: left;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  justify-content: flex-start;
  justify-items: start; /* Add this line */
  background: #F2FAFF;
  border-radius: 8px;
  margin-top: 32px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 40px;
}
.power-search-results-divarchivio {

    width: auto;
    min-height: 610px;
    display: flex;
    align-items: left;
    margin-left: 0px;
    flex-direction: column;
    justify-content: flex-start;
  
}
.archivio-cartelle-condivise-line16 {
  top: 0px;
  left: 0px;
  width: 1067px;
  height: 0px;
  position: absolute;
}
.archivio-cartelle-condivise-rectangle4362 {
  top: 16px;
  left: -20px;
  width: 1058px;
  height: 287px;
  position: absolute;
  border-color: transparent;
  border-radius: 8px;
}
.archivio-cartelle-condivise-group29 {
  top: 74px;
  left: 39px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-cartelle-condivise-rectangle6 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.archivio-cartelle-condivise-dots1 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.archivio-cartelle-condivise-folder-blank1 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.archivio-cartelle-condivise-text {
  top: 109.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-text02 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-text04 {
  top: 157.5771484375px;
  left: 128.78158569335938px;
  color: rgba(161, 161, 161, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-group50 {
  top: 74px;
  left: 253.783203125px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-cartelle-condivise-rectangle61 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.archivio-cartelle-condivise-dots11 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.archivio-cartelle-condivise-folder-blank11 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.archivio-cartelle-condivise-text06 {
  top: 109.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-text08 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-text10 {
  top: 157.5771484375px;
  left: 128.78125px;
  color: rgba(161, 161, 161, 1);
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-cartelle-condivise-text12 {
display: flex;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  left: 22px;
  top: 15px;
}

