.motorepredefdiv {
    margin-left: 20px;
    margin-top: 15px; 

    width: 158px;
    height: auto;
    background: #FFFFFF;
    border: 2px solid  #E2E3E3;
    border-radius: 7px;
    font-family: "Encode Sans Expanded";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    color: #1D1D1B;
    padding: 10px 14px;
    cursor: pointer;

    }
    