.social-search-result-container {
  width: 100%;
  /* height: 400px; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
}
.social-search-result-social-result-main-div {
  flex: 0 0 auto;
  width: 673px;
  height: 141px;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.social-search-result-social-result-link-description-title {
  flex: 0 0 auto;
  width: 80%;
  height: 117px;
  display: flex;
  align-items: flex-start;
  margin-left: 24px;
  flex-direction: column;
  justify-content: center;
}
.social-search-result-social-link {
  font-size: 13px;
  font-family: "Encode Sans Expanded";
}
.social-search-result-social-title {
  color: #0021d1;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
}
.social-search-result-social-description {
  font-size: 15px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
}
.social-search-result-share-icon-div {
  width: 152px;
  height: 141px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.social-search-result-share-icon {
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-right: 25px;
}
.social-search-result-facebook-icon {
  width: 23px;
  height: 23px;
  object-fit: cover;
  margin-right: 25px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 1200px) {
  .social-search-result-facebook-icon {
    width: 23px;
    height: 23px;
  }
}
