.plan-sign-up-enterprise-container {
  width: 772px;
  height: 611px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}
.testopersonalizzato {
  font-size: 16px;
}
.plan-sign-up-enterprise-frame5176 {
  width: 612px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5148 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 30px;
}
.plan-sign-up-enterprise-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5175 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.plan-sign-up-enterprise-frame5160 {
  display: flex;
  padding: 24px;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(26, 28, 30, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 32px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}
.plan-sign-up-enterprise-frame5159 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5158 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
  flex-direction: column;
}
.plan-sign-up-enterprise-text02 {
  color: rgba(121, 121, 121, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame53 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;

}
.plan-sign-up-enterprise-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 4px;
  margin-bottom: 0;
  text-decoration: none;
  
}
.plan-sign-up-enterprise-text06 {
  color: rgba(121, 121, 121, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5157 {
  display: flex;
  position: relative;
  align-items: flex-end;
  border-color: transparent;
  flex-direction: column;
  margin-top: 19px;
}
.plan-sign-up-enterprise-frame5156 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 17px;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5149 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle15 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-frame32 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.plan-sign-up-enterprise-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame4 {
  width: 200px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(241, 241, 241, 1);
}
.plan-sign-up-enterprise-frame37 {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  justify-content: space-between;
}
.plan-sign-up-enterprise-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.plan-sign-up-enterprise-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
}
.plan-sign-up-enterprise-frame5150 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle11 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5151 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle12 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text14 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5152 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle13 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5153 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle14 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5154 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle16 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5155 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.plan-sign-up-enterprise-checkmarkcircle17 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text22 {
  color: rgba(26, 28, 30, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame91 {
  width: 240px;
  display: flex;
  padding: 6px 12px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(255, 153, 0, 1);
  margin-top: px;
}
.plan-sign-up-enterprise-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 8px;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-vector21 {
  width: 8px;
  height: 4px;
  position: relative;
}
.plan-sign-up-enterprise-frame5174 {
  display: flex;
  padding: 14px 24px;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(26, 28, 30, 0.10000000149011612) ;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}
.plan-sign-up-enterprise-frame5173 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5163 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 0px;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5161 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}
.plan-sign-up-enterprise-text26 {
  color: rgba(121, 121, 121, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5162 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-top: -18px;
}
.plan-sign-up-enterprise-text28 {
  color: rgba(121, 121, 121, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5172 {
  display: flex;
  position: relative;
  align-items: flex-end;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5171 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 17px;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame5164 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle115 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-frame94 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.plan-sign-up-enterprise-frame331 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.plan-sign-up-enterprise-text30 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame31 {
  width: 216px;
  display: flex;
  padding: 8px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.plan-sign-up-enterprise-text32 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  background-color: transparent;
}
.plan-sign-up-enterprise-frame5165 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle116 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text34 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5166 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle117 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text36 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5167 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle118 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text38 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5168 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle119 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text40 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5169 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
}
.plan-sign-up-enterprise-checkmarkcircle120 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text42 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame5170 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.plan-sign-up-enterprise-checkmarkcircle121 {
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 8px;
}
.plan-sign-up-enterprise-text44 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-frame95 {
  width: 240px;
  display: flex;
  padding: 6px 12px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(255, 153, 0, 1);
}
.plan-sign-up-enterprise-text46 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 8px;
  margin-bottom: 0;
  text-decoration: none;
}
.plan-sign-up-enterprise-vector22 {
  width: 8px;
  height: 4px;
  position: relative;
}
