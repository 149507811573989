.coupon-welfare-container {
  width: 671px;
  height: 181px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.coupon-welfare-coupon-welfare-container {
  width: 671px;
  height: 191px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.coupon-welfare-frame5024 {
  width: 671px;
  display: flex;
  padding: 24px 0 24px 24px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.coupon-welfare-frame5022 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.coupon-welfare-frame5021 {
  width: 585px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.coupon-welfare-frame5020 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
  flex-direction: column;
}
.coupon-welfare-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.coupon-welfare-text02 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.coupon-welfare-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.coupon-welfare-frame5008 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: space-between;
}
.coupon-welfare-frame5030 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.coupon-welfare-image9 {
  width: 24px;
  height: 24px;
  position: relative;
  border-color: transparent;
  margin-right: 16px;
  object-fit: cover;
}
.coupon-welfare-frame5003 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.coupon-welfare-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.coupon-welfare-frame5004 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.coupon-welfare-text11 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.coupon-welfare-frame13 {
  display: flex;
  padding: 6px 12px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(255, 153, 0, 1);
}
.coupon-welfare-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 8px;
  margin-bottom: 0;
  text-decoration: none;
}
.coupon-welfare-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
}
.coupon-welfare-root-class-name {
  margin-bottom: var(--dl-space-space-unit);
}
.coupon-welfare-root-class-name1 {
  margin-bottom: var(--dl-space-space-unit);
}
.coupon-welfare-root-class-name2 {
  margin-bottom: var(--dl-space-space-unit);
}
@media screen and (max-width: 707px) {
  .welfare-enterprise-component-container {
    width: 300px;
  }
  .coupon-welfare-container {
    align-items: center;
    height: 220px;
    margin-left: 20px;
  }
  .coupon-welfare-coupon-welfare-container {
width: 343px;
align-items: center;
  } 
  .surf3 {
    margin-top: -34px;
  }
  .coupon-welfare-frame5024 {
    width: 343px;
  }
  .coupon-welfare-frame5021 {
    width: 300px;
  }
}