.privacyPolicy {
    font-family: "Encode Sans Expanded";
    width: 50%;
    text-align: justify;
    margin-top: -100px;
}
@media screen and (max-width: 730px) {
    .privacyPolicy {
        width: 80%;
    }
}   

@media screen and (max-width: 400px) {
    .privacyPolicy {
        width: 90%;
    }
    
}  