.accountListWidgetsContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
gap: 22px;
}
.AccountListNameSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 60px;
text-align: center;
}
.accountListTitle {
    font-family: "Encode Sans Expanded";
font-size: 28px;
font-weight: 500;
line-height: 35px;
letter-spacing: 0em;

color: #063565;

}
.email-overlay {
    position: absolute;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
    z-index: 1;
    margin-top: 10px;
  }
  
.AccountListNameSearchparent {
    display: flex;
    flex-direction: column;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 33; /* Make sure this is above other page content */
  }
  
  /* Style for the pop-up close button, you can adjust as per your design */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
