.frame-container-childNewGroupPopUp {
    width: 410px;
    height: 423px;
    position: relative;
    border-radius: 6px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: none;
  }
  .move-accountsNewGroupPopUp {
    margin: 0;
    height: 26px;
    position: relative;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    z-index: 1;
  }
  .move-accounts-labelNewGroupPopUp {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 6px;
  }
  .choose-the-groupNewGroupPopUp {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 100%;
    font-weight: 300;
    color: #787777;
    text-align: center;
    z-index: 1;
  }
  .nested-frameNewGroupPopUp {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 19px;
  }
  .background-childNewGroupPopUp {
    height: 30px;
    width: 246px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #c7c6c6;
    box-sizing: border-box;
    display: none;
  }
  .chose-a-groupNewGroupPopUp,
  .iconNewGroupPopUp {
    position: relative;
    z-index: 2;
  }
  .chose-a-groupNewGroupPopUp {
    letter-spacing: -0.02em;
    line-height: 100%;
    font-weight: 300;
  }
  .iconNewGroupPopUp {
    height: 14px;
    width: 14px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .backgroundNewGroupPopUp {
    width: 246px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #c7c6c6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7px 9px 8px 23px;
    z-index: 1;
    font-size: 11px;
    color: #787777;
  }
  .rectangle-shapeNewGroupPopUp {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 6px;
    background-color: #ffe299;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  .createNewGroupPopUp {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    font-family: "Encode Sans Expanded";
    color: #063565;
    text-align: left;
    z-index: 1;
  }
  .bottone-gruppoNewGroupPopUp,
  .rectangle-shape-parentNewGroupPopUp {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .rectangle-shape-parentNewGroupPopUp {
    justify-content: flex-start;
    padding: 6px 39px 5px 30px;
    position: relative;
  }
  .bottone-gruppoNewGroupPopUp {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    justify-content: center;
    z-index: 1;
  }
  .button-group-instanceNewGroupPopUp {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .account-move-accounts-to-groNewGroupPopUp,
  .frame-containerNewGroupPopUp {
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
  }
  .frame-containerNewGroupPopUp {
    flex: 1;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    justify-content: flex-start;
    padding: 83px 78px 123px 72px;
    gap: 41px;
    text-align: left;
    font-size: 21px;
    color: #063565;
    font-family: "Encode Sans Expanded";
  }
  .account-move-accounts-to-groNewGroupPopUp {

    width: 410px;
    height: 423px;
  align-self: center;
  margin-left: 400px;
  margin-top: 200px;
  }
  .chose-a-group-dropdownNewGroupPopUp {
    width: 100%; /* Make the dropdown full width */
    background-color: transparent; /* Make the dropdown background transparent */
    border: 1px solid #ccc; /* Optional: adds a light border to define the dropdown */
    padding: 10px; /* Optional: adds some padding inside the dropdown for better text visibility */
    color: #333; /* Optional: sets the text color */
    -webkit-appearance: none; /* Removes default styling on iOS */
    -moz-appearance: none; /* Removes default styling in Firefox */
    appearance: none; /* Removes default browser styling */
}

/* You might want to style the dropdown arrow as well, especially since the default arrow might not be visible with a transparent background. */
.chose-a-group-dropdownNewGroupPopUp::-ms-expand {
    display: none; /* Hide the default arrow in IE */
}

/* Custom arrow styles or use a background-image for a custom arrow */
