/* aiTokenManagement.css */
.ai-token-management-container {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #FBFAFB;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  height: 310px;
  font-family: "Encode Sans Expanded";
  max-width: 591px; /* Adjust based on your layout */

}

.ai-token-management-section, .token-distribution {
  width: calc(50% - 10px);
  margin-right: 20px;
}

.ai-token-management-section {
  margin-right: 20px;
}

.ai-token-management-section h2 {
  color: #063565;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
.token-distribution h2 {
  color: #063565;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 4px;
}
.total-assigned-tokens {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 500px;
  margin-left: 110px;
  margin-bottom: 30px;
}
.token-table-section {
  max-height: 200px;
  height: 200px;
  overflow-x: hidden;
}
.AitokensTableRows {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.token-table-section h3 {
  color: #063565;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 220% */
  border-bottom: 2px solid var(--Light-Border-Light, #DFDFDF);
}
.ai-token-management-section p, .token-distribution  {
  color: #787777;
  width: 238px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
}
.ai-token-info {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.ai-token-data {
  display: flex;
  gap: 22px;
  color: #063565;
  font-family: "Encode Sans Expanded";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.token-table-section:first-child {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 6px 6px 0px 0px;
}
.token-table-section:last-child {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 0px 0px 6px 6px;
}
.token-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #EAEAEA;
}

.token-table-row span {
  color: #787777;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 220% */
  text-align: left; /* Align the numbers to the left */
}


.token-distribution {
  margin-left: 20px;
  margin-right: 0;
}



.token-table-row {
  margin-bottom: 5px;
}

/* Add additional responsive styling as needed */

