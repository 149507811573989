.filtro-categorie-filtro-categorie {
  width: 277px;
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px
}
.filtro-categorie-categoria-suggerita {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 20px;
  text-decoration: none;
}

.filtro-categorie {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 10px;
  text-decoration: none;
  display: flex;
}
.filtro-categorie-scienza-e-tecnologia {
  top: 58px;
  left: 48px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.filtro-categorie-sport {
  top: 126px;
  left: 48px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.filtro-categorie-sport1 {
  top: 238.106201171875px;
  left: 48px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.filtro-categorie-intrattenimento {
  top: 92px;
  left: 48px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.filtro-categorie-salute {
  top: 160px;
  left: 48px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.filtro-categorie-checkbox {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-checkbox2 {
  top: 59.5px;
  left: 24px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-checkbox3 {
  top: 93.5px;
  left: 24px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-checkbox4 {
  top: 127.5px;
  left: 24px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-checkbox5 {
  top: 239.606201171875px;
  left: 24px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-checkbox6 {
  top: 161.5px;
  left: 24px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.filtro-categorie-input-double-search {
  left: 24px;
  width: 229px;
  height: 46px;
  box-sizing: border-box;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  border-radius: 7px;
  margin-bottom: 0;
}
.filtro-categorie-cerca-key-word {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  padding: 15px 20px;
  box-sizing: border-box;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
}

.filtro-categorie-root-class-name {
  top: 40px;
  left: 0px;
  position: absolute;
  z-index: 100;
}
