.notification-overview {
    background-color: #F9F9F9;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    font-family: "Encode Sans Expanded";
    height: 355px;
    width: 800px;

  }
  
  .notification-title {

    margin-bottom: 20px;
    font-size: 18px;
font-weight: 500;

color: #063565;
}
  
  .notifications-container {
    overflow-y: auto;
    max-height: 280px; /* Adjust as necessary */
    overflow-x: hidden;
  }
  
  .notification-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-left: 5px solid; /* Color will be added with class */
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #00000040;

    position: relative;
  }
  .delete-button {
   
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px; /* Adjust as necessary */
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px; /* Adjust as necessary */
    color: #e74c3c; /* Adjust as necessary */
  }
  
  .delete-button:hover {
    color: #c0392b; /* Darker shade for hover effect */
  }
  
  /* Ensure the notification content doesn't overlap with delete button */
  .notification-content {
    margin-right: 30px; /* Adjust as necessary */
    font-weight: 300;
    font-size: 12px;
    color: #4A4A4A;
  }
  .notification-item.red {
    border-color: #e74c3c;
  }
  
  .notification-item.green {
    border-color: #2ecc71;
  }
  
  .notification-item.orange {
    border-color: #f39c12;
  }
  
  .notification-date {
    flex: 0 0 100px; /* Adjust width as necessary */
    font-weight:400;
    font-size: 12px;
    color: #4A4A4A;
  }
  
  .notification-content {
    flex: 1;
    margin-left: 0px;
  }
  
  .notification-icon {
    cursor: pointer;
    /* Add icon styles here */
  }
  
  /* Add responsive design and other styling as needed */
  