.close{
    /* background-color: red; */
    position: absolute;
    bottom: 35px;
    right: 10px;
}
.pop-up-folder1-container {
    z-index: 100;
    right: 0px;

    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.pop-up-folder1-frame108 {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
}

.pop-up-folder1-filtro-data {
    display: flex;
    padding: 17px 0;
    position: relative;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.pop-up-folder1-frame115 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
    justify-content: center;
}

.pop-up-folder1-group114 {
    width: 179px;
    height: 39px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 13px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.pop-up-folder1-group110 {
    top: 0px;
    left: 0px;
    width: 179px;
    height: 39px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    border-radius: 0px 0px 0px 0px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: rgba(241, 241, 241, 1);
}

.pop-up-folder1-frame1151 {
    top: 10px;
    left: var(--dl-space-space-unit);
    display: flex;
    position: absolute;
    align-items: flex-start;
    border-color: transparent;
}

.pop-up-folder1-pen-edit-write1 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
}

.pop-up-folder1-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-folder1-frame111 {
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    border-color: transparent;
    margin-bottom: 13px;
}

.pop-up-folder1-trashdeletebin21 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
}

.pop-up-folder1-text02 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-folder1-frame112 {
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    border-color: transparent;
    margin-bottom: 13px;
}

.pop-up-folder1-foldermove1 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
}

.pop-up-folder1-text04 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-folder1-frame113 {
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    border-color: transparent;
    margin-bottom: 13px;
}

.pop-up-folder1-share11 {
    width: 18px;
    height: 18px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 8px;
}

.pop-up-folder1-group {
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.pop-up-folder1-vector {
    top: 6.999713897705078px;
    left: 2.9999947547912598px;
    width: 4px;
    height: 4px;
    position: absolute;
}

.pop-up-folder1-vector1 {
    top: 3.000001907348633px;
    left: 10.999532699584961px;
    width: 4px;
    height: 4px;
    position: absolute;
}

.pop-up-folder1-vector2 {
    top: 10.999464988708496px;
    left: 10.999532699584961px;
    width: 4px;
    height: 4px;
    position: absolute;
}

.pop-up-folder1-vector3 {
    top: 5.887500286102295px;
    left: 6.779999732971191px;
    width: 4px;
    height: 2px;
    position: absolute;
}

.pop-up-folder1-vector4 {
    top: 9.892498970031738px;
    left: 6.779999732971191px;
    width: 4px;
    height: 2px;
    position: absolute;
}

.pop-up-folder1-text06 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-folder1-frame109 {
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    border-color: transparent;
}

.pop-up-folder1-group-user12 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
}

.pop-up-folder1-text08 {
    color: rgba(217, 217, 217, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: right;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}
