.weagle-container {
    background-color: #FFFAF0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: none;
}

.weagle-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.weagle-title {
    font-size: 32px;
    font-weight: bold;
    margin-right: 10px;
}

.weagle-subtitle {
    font-size: 18px;
    color: #999;
}

.weagle-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    background-size: cover;
}

.chatbase-icon {
    background-image: url('path_to_chatbase_icon.png');
}

.upload-docs-icon {
    background-image: url('path_to_upload_docs_icon.png');
}

/* ... repeat for other icons using their respective background-image URLs ... */
