.web-search-result-list-container {
  width: 100%;
  height: 141px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 440px;
}
.web-search-result-list-web-result-main-div-archivio-dettaglio {
  flex: 0 0 auto;
  width: 673px;
  height: 141px;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 0px;
  border-radius: 7px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.web-search-result-list-web-result-main-div {
  flex: 0 0 auto;
  width: 673px;
  height: 141px;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 0px;
  border-radius: 7px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.web-search-result-list-web-result-link-title-description-div {
  flex: 0 0 auto;
  width: 80%;
  height: 117px;
  display: flex;
  align-items: flex-start;
  margin-left: 24px;
  flex-direction: column;
  justify-content: center;
}
.web-search-result-list-web-result-link {
  font-size: 13px;
  font-family: "Encode Sans Expanded";
}
.web-search-result-list-web-result-title {
  color: #0021d1;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
}
.web-search-result-list-web-result-description {
  font-size: 15px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
}
.web-search-result-list-web-result-share-icon-div {
  z-index: unset;
  width: 152px;
  height: 25px;


}
.web-search-result-list-share-icon {
  width: 24px;
 
  object-fit: cover;
  margin-right: var(--dl-space-space-oneandhalfunits);
  position: absolute;
}

.web-search-result-list-root-class-name1 {
  margin-top: 0;
}
.web-search-result-list-root-class-name2 {
  margin-top: var(--dl-space-space-unit);
}
.web-search-result-list-root-class-name3 {
  margin-top: var(--dl-space-space-unit);
}

.web-search-result-list-web-result-threat-score-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
;
  margin-left: 10px;
  font-family: "Encode Sans Expanded";

}

.web-search-result-list-web-result-threat-score-radial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
}

.web-search-result-list-web-result-threat-score-hole {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-search-result-list-web-result-threat-score-text {

  font-size: 16px;
  font-weight: bold;
  color: #063565;
}
.web-search-result-list-web-result-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

}
@media screen and (max-width: 1007px) {
  .web-search-result-list-container {
    width: 100%;
    height: 141px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
  }
}
@media screen and (max-width: 707px) {
  .web-search-result-list-web-result-threat-score-container {
    display: none;
  
  
  }
}
