.advanced-filters-container {
  width: 100%;
  display: flex;

  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.advanced-filters-container::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}
.advanced-filters-container-principale {
  width: 100%;
  height: 287px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.advanced-filters-container1 {
  width: 211px;
  height: 306px;
  display: flex;
  margin-top: 55px;
  align-items: flex-start;
  justify-content: center;
}
.advanced-filters-results-div {
  width: 671px;
  height: 610px;
  display: flex;
  margin-top: -89px;
  align-items: center;
  margin-left: -20px;
  flex-direction: column;
  justify-content: flex-start;
}
@media(max-width: 1200px) {
  .advanced-filters-results-div {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 670px) {
  .advanced-filters-results-div {
 width: 100%;
 margin-left: 24px;
 margin-top: -176px;
  }

  .component-advanced-search-frame13 {
    cursor: pointer;
    display: flex;
    padding: 14px 58px;
    position: fixed;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(255, 153, 0, 1);
    z-index: 1000;
  }
  .component-advanced-search-container {
    width: 100%;
  }
  .component-advanced-search-frame5115 {
    width: 90%;
  }
  .component-advanced-search-frame31 {
    width: 280px;
  }
}