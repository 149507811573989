.archivio-component-detail-container {
  width: 1067px;
  height: 716px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -184px;
margin-left: 100px;
}
.NomeCartellaDettaglio {
  font-family: "Encode Sans Expanded";
  font-weight: 400;
}


