.loader-containerloaderSearchMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    align-self: left;
  }
  
  .loaderSearchMobile {
    border: 18px solid #f3f3f3;
    border-top: 18px solid rgb(253, 153, 39);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
    margin-top: 90px;
    margin-bottom: 25px;
  }
  
  .loader-textloaderSearchMobile {
    font-size: 16px;
    color: #333;
    font-family: "Encode Sans Expanded";
    text-align: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

