.pop-up-save-link1-container {
    width: 218px;
    height: 172px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
  }
  .pop-up-save-link1-filtro-data {
    width: 100%;
    height: 172px;
    display: flex;
    overflow: hidden;
    position: relative;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 1);
  }
  .pop-up-save-link1-frame119 {
    top: 26.5px;
    left: 19.0947265625px;
    width: 140px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .pop-up-save-link1-frame5141 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .pop-up-save-link1-group115 {
    width: 140px;
    height: 19.999998092651367px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 14px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .pop-up-save-link1-arrow261 {
    width: 20px;
    height: 20px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .pop-up-save-link1-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    white-space: nowrap
  }
  .pop-up-save-link1-group116 {
    width: 124px;
    height: 20px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 14px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .pop-up-save-link1-bookmarkfavorite13 {
    width: 20px;
    height: 20px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .pop-up-save-link1-text2 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    white-space: nowrap;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .pop-up-save-link1-group117 {
    width: 107px;
    height: 20px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 14px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .pop-up-save-link1-messageschattext1 {
    width: 20px;
    height: 20px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .pop-up-save-link1-text4 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    white-space: nowrap;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .pop-up-save-link1-group118 {
    width: 120px;
    height: 20px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .pop-up-save-link1-share11 {
    width: 20px;
    height: 20px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: var(--dl-space-space-halfunit);
  }
  .pop-up-save-link1-group {
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .pop-up-save-link1-vector {
    top: 7.777459144592285px;
    left: 3.3333277702331543px;
    width: 4px;
    height: 4px;
    position: absolute;
  }
  .pop-up-save-link1-vector1 {
    top: 3.3333353996276855px;
    left: 12.22170352935791px;
    width: 4px;
    height: 4px;
    position: absolute;
  }
  .pop-up-save-link1-vector2 {
    top: 12.22162914276123px;
    left: 12.22170352935791px;
    width: 4px;
    height: 4px;
    position: absolute;
  }
  .pop-up-save-link1-vector3 {
    top: 6.5416669845581055px;
    left: 7.533332824707031px;
    width: 5px;
    height: 2px;
    position: absolute;
  }
  .pop-up-save-link1-vector4 {
    top: 10.99166488647461px;
    left: 7.533332824707031px;
    width: 5px;
    height: 2px;
    position: absolute;
  }
  .pop-up-save-link1-text6 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    white-space: nowrap;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
