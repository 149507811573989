.component-head-archivio-container {
  width: 1067px;
  height: 172px;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.component-head-archivio-group92 {
  width: 100%;
  height: 172px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.classedettagliotitolo {
  font-size: 23px;
  font-weight: 400;
  font-family: 'Encode Sans Expanded';
}
.indietroButtonArchivio { 
  font-size: 16px;
  font-weight: 300;
  font-family: 'Encode Sans Expanded';
  cursor: pointer;
  z-index: 10;
}
.component-head-archivio-group92-archivio-dettaglio {
  width: 100%;
  height: 172px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-head-archivio-group91 {
  top: 0px;
  left: 0px;
  width: 1067px;
  height: 172px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-head-archivio-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-textArchivio {
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-text02 {
  top: 38px;
  color: rgba(81, 81, 81, 1);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-text02Archivio {
  top: 38px;
  color: rgba(81, 81, 81, 1);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-rectangle69 {
  top: 110px;
  left: 0px;
  width: 395px;
  height: 46px;
  position: absolute;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
}
.component-head-archivio-gridlayout101 {
  top: 121px;
  left: 903px;
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.component-head-archivio-group {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-head-archivio-vector {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  position: absolute;
}
.component-head-archivio-vector1 {
  top: 3px;
  left: 14px;
  width: 7px;
  height: 7px;
  position: absolute;
}
.component-head-archivio-vector2 {
  top: 14px;
  left: 3px;
  width: 7px;
  height: 7px;
  position: absolute;
}
.component-head-archivio-vector3 {
  top: 14px;
  left: 14px;
  width: 7px;
  height: 7px;
  position: absolute;
}
.component-head-archivio-line16 {
  top: 202px;
  left: 0px;
  width: 1067px;
  height: 0px;
  position: absolute;
}
.component-head-archivio-line16Indietro {
  top: 172px;
  left: 0px;
  width: 1067px;
  height: 0px;
  position: absolute;

}
.component-head-archivio-text07 {
  top: 123px;
  left: 16px;
  color: rgba(161, 161, 161, 1);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-text09 {
  top: 124px;
  left: 707.5419921875px;
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.component-head-archivio-component3 {
  top: 124px;
  left: 775px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.component-head-archivio-text11 {
  color: rgba(255, 153, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-head-archivio-frame13 {
  cursor: pointer;
  top: 114px;
  left: 951px;
  display: flex;
  padding: 10px 14px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(255, 153, 0, 1);
}
.bottoneFloatArchivio {
  cursor: pointer;
  display: none;
  padding: 20px 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  align-items: center;
  border-color: transparent;
  border-radius:100%;
  background-color: rgba(255, 153, 0, 1);
  z-index: 1000;
}
.component-head-archivio-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: 'Encode Sans Expanded';
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  white-space: nowrap
}

@media screen and (max-width: 710px) {
  .classedettagliotitolo {
  margin-left: 160px;
  font-size: 20px;
  width: 270px;
  }
}