.new-account-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Make sure this is a high value to cover other elements */
  }
  
  .new-account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F9F9F9;
    padding: 20px;
    border-radius: 6px;
    position: relative; /* For absolute positioning of the close button */
    box-shadow: 0px 4px 4px 0px #00000040;
font-family: "Encode Sans Expanded";
    min-width: 622px; /* Adjust the width as necessary */
    min-height: 400px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #aaa;
    cursor: pointer;
  }
  
  .close:hover {
    color: black;
  }
  
  .new-account-header {
    text-align: center;
    font-size: 21px;
    color: #063565;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .new-account-instructions {
    text-align: center;
    font-size: 14px;
    color: #787777;
    margin-bottom: 30px;

    font-weight: 300;
  }
  
  .new-account-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .new-account-inputs {
    display: flex;
    flex-direction: column;
  }
  .new-account-form label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #063565;
    font-weight: 500;
    text-align: left;
  }
  
  .new-account-form input {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
     background: #F9F9F9;
     font-size: 14px;
     color: #063565;
     font-weight: 300;
     width: 410px;
     height: 50px;
  }
  
  .new-account-create-btn {
    background-color: #FFE299;
   
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 2px 2px 0px #00000040;
font-size: 12px;
white-space: nowrap;
font-weight: 500;
color: #063565;
align-self: flex-end;
width: 100px;
height: 31px;
margin-top: 20px;
  }
  .new-account-create-btn2 {
    background-color: #fff;
   
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 2px 2px 0px #00000040;
font-size: 12px;
white-space: nowrap;
font-weight: 500;
color: #063565;
align-self: flex-end;
width: 100px;
height: 31px;
margin-top: 20px;
  }
  .password-input {
    position: relative;
  }
  
  .password-eye {
    position: absolute;
    width: 20px;
        top: 32%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 999;
    color: gray;
  }
  
  .eye-icon {
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
  }
  

  