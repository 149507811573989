.unprotectedWidgetContainer {
    display: flex;
    flex-direction: column;
    background: #E64436;

  width: 160px;
box-shadow: 0px 4px 4px 0px #00000040;
border-radius: 6px;
justify-content: center;
align-items: center;
font-family: "Encode Sans Expanded";
color: #fff;
text-align: center;
padding: 30 22 30 22;
gap: 5px;
height: 129px;
}
.unprotectedWidgetNumber {
font-weight: 700;
font-size: 36px;
}
.unprotectedWidgetText {
    font-weight: 300;
    font-size: 12px;
}