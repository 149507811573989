.invite-pop-up-container {
    width: 664px;
    height: 752px;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
  }
.invited-pop-up-container {
  height: 752px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
  .invite-pop-up-frame5197 {
    width: 387px;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    flex-direction: column;
  }
  .invite-pop-up-frame5191 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    margin-bottom: 10px;
    flex-direction: column;
  }
  .invite-pop-up-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 24px;
    align-self: auto;
    font-style: Bold;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 8px;
    text-decoration: none;
  }
  .invite-pop-up-text02 {
    color: rgba(121, 121, 121, 1);
    width: 472px;
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Medium;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 30px;
    text-decoration: none;
  }
  .invite-pop-up-frame5196 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
    flex-direction: column;
  }
  .invite-pop-up-frame5194 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 10px;
    flex-direction: column;
  }
  .invite-pop-up-frame33 {
    display: flex;
    padding: 0 8px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 0px;
  }
  .invite-pop-up-text04 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .invite-pop-up-frame5193 {
    display: flex;
    padding: 10px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
 
 
  }
  .invite-pop-up-frame31input {
    width: 389px;
    min-height: 303px;
    max-height: 303px;
    overflow-y: scroll;
    display: flex;
    padding: 16px 24px;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    flex-direction: column;
    background-color: rgba(251, 251, 251, 1);
  }
  .invite-pop-up-frame5192 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .invite-pop-up-frame5032 {
    display: flex;
    padding: 4px 11px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    margin-bottom: 8px;
    justify-content: center;
    background-color: rgba(243, 243, 243, 1);
  }
  .invite-pop-up-text06 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 10px;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
  }
  .invite-pop-up-group3 {
    width: 6px;
    height: 6px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .invite-pop-up-vector {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-vector1 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-frame5033 {
    display: flex;
    padding: 4px 11px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    margin-bottom: 8px;
    justify-content: center;
    background-color: rgba(243, 243, 243, 1);
  }
  .invite-pop-up-text08 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 10px;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
  }
  .invite-pop-up-group31 {
    width: 6px;
    height: 6px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .invite-pop-up-vector2 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-vector3 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-frame5034 {
    display: flex;
    padding: 4px 11px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    margin-bottom: 8px;
    justify-content: center;
    background-color: rgba(243, 243, 243, 1);
  }
  .invite-pop-up-text10 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 10px;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
  }
  .invite-pop-up-group32 {
    width: 6px;
    height: 6px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .invite-pop-up-vector4 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-vector5 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .invite-pop-up-frame5035 {
    display: flex;
    padding: 4px 11px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    justify-content: center;
  }
  .invite-pop-up-text12 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
  }
  .invite-pop-up-frame5195 {
    display: flex;
    padding: 0px;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .invite-pop-up-frame13button {
    display: flex;
    padding: 10px 14px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(255, 153, 0, 1);
  }
  .invite-pop-up-text14 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }

  .closeButtonInvite {
    margin-left: 16px;
    width: 17px;
  }
