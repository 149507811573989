.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: "Encode Sans Expanded";
  }
  
  .formChange {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
  
    border-radius: 5px;
    font-family: "Encode Sans Expanded";
  }
  
  button {

    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    

    background-color: #ff9900;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    font-family: "Encode Sans Expanded";
  }
  .loading-button {
    background-color: white;
   
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  input {

  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .loginpasswordforgot {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    form {
      max-width: 90%;
    }
  }
  