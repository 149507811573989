.welfare-header-aziendale-container {
  width: 325px;
  height: 165px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
  .indietrobutton2 {
  
  margin-right: 70%;
text-decoration: underline;
font-family: "Encode Sans Expanded";
font-size: 16px;
cursor: pointer;
} 
.welfare-header-aziendale-header-welfare-and-filters {
  width: 100%;
  height: 164.724609375px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.welfare-header-aziendale-group99 {
  top: 0px;
  left: 0px;
  width: 325px;
  height: 164.724609375px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.welfare-header-aziendale-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.welfare-header-aziendale-text2 {
  top: 45px;
  left: 80px;
  color: rgba(81, 81, 81, 1);
  width: 245px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.welfare-header-aziendale-frame1 {
  top: 130.724609375px;
  left: 11.16668701171875px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.welfare-header-aziendale-text4 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.welfare-header-aziendale-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
}
.welfare-header-aziendale-frame4 {
  top: 130.724609375px;
  left: 126.16668701171875px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.welfare-header-aziendale-text6 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.welfare-header-aziendale-vector21 {
  width: 8px;
  height: 4px;
  position: relative;
}
.testopersonalizzatoMobile {
  display: none;
}
.welfare-header-aziendale-giftbox1 {
  top: 43px;
  left: 0px;
  width: 64px;
  height: 64px;
  position: absolute;
  border-color: transparent;
}
.welfare-header-aziendale-root-class-name {
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}
@media screen and (max-width: 1103px) {
  .searchbarwelfare {
  display: none;
  }
  
}
@media screen and (min-width: 790px) {
.indietroButton {
  display: none;
}
.indietroButtonSignUp {
  display: none;
}
}
@media screen and (max-width: 790px) {
  .indietroButton {
margin-left: -40%;
margin-top: -20px;
margin-bottom: 50px;
   font-family: "Encode Sans Expanded";
   text-decoration: underline;
   font-size: 12px;
  }
  .indietrobutton2 {
  
    margin-right: 40%;
  text-decoration: underline;
  font-family: "Encode Sans Expanded";
  font-size: 16px;
  cursor: pointer;
  } 
  .sign-up-pop-up-c1-text {
font-size: 20px;
margin-top: 10px;
  }
  .indietroButtonSignUp {
  
    margin-left: 4%;
  text-decoration: underline;
  font-family: "Encode Sans Expanded";
  font-size: 12px;
  margin-top: 10px;
}
  .enterprise-sign-up-frame5144 {
    margin-top: 20px;
  }
  .user-sign-up-frame5143 {
    margin-top: 20px;
  }
  .testopersonalizzato {
    display: none;
  }
  .testopersonalizzatoMobile {
    display: flex;
  }
  .plan-sign-up-enterprise-frame5175 {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .plan-sign-up-enterprise-frame5174 {
    margin-bottom: 100px;
  }
  .plan-sign-up-enterprise-frame5160 {
    margin-right: 0px;
    margin-top: 40vh;
  }
  .stripe-payment-plan-frame5180 {
    width: 50%;
  }
}
@media screen and (max-width: 532px) {
  .indietroButtonSignUp {
  
    margin-left: 23%;
  
  }
}
@media screen and (max-width: 707px) {
  .searchbarwelfare {
  display: none;
  }
 .welfare-header-aziendale-group99 {
  left: 180px;
 }
 .msgnonregWelfare {
  width: 50%;



}
.login-pop-up-text {
  width:90vw;
  align-self: center;
}

}