.overviewWidgetsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
gap: 22px;
margin-top: 100px;
padding-bottom: 100px;
}
.overviewWidgetsContainerc1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.WelcomeOverviewDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 gap: 515px;
 width: 1020px;
  
}
.WelcomeOverviewDivc1 {
   gap: 20px;
   display: flex;
  
   white-space: nowrap;
}
.overviewWidgetAccountsInfo {
font-size: 30px;
font-weight: 500;
line-height: 38px;
letter-spacing: 0em;
text-align: center;
color:  #E64436;

}
.overviewWidgetAccountsInfo2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color:  #09325D;
    
    }
.WelcomeOverviewDivc2 {
    display: flex;
}
/* .overviewWidgetWelcomeDivide {
border: #000 1px solid;
opacity: 0.12;
margin: 0 5 0 5;
} */
.WelcomeOverviewDivc2c1 {
    display: flex;
    flex-direction: column;
    gap: 5px ;
}
.WelcomeOverviewDivcSeats {
    display: flex;
    flex-direction: row;
    gap: 5px ;
}
.WelcomeOverviewDivc2c2 {
    display: flex;
    gap: 5px ;
}
.overviewWidgetAccountsInfospanDiv {
    display: flex;
    flex-direction: column;
}

.overviewWidgetAccountsInfoSpan {
font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: #063565;

}
.overviewWidgetAccountsInfoSpan2 {
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #063565;
    
    }
.overviewWidgetWelcome {
    font-family: "Encode Sans Expanded";
font-size: 21px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #063565;

}
.overviewWidgetName {
    font-family: "Encode Sans Expanded";
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF9900;
    
}
.overviewWidgetsContainerc2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.overviewWidgetsContainerc2c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;
}
.overviewWidgetsContainerc2c1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.overviewWidgetsContainerc2c2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}





.addAccountPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Encode Sans Expanded";
  }
  
  .addAccountPopupContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 671px;
    height: 625px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  

  .addAccountPopupTitle1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #063565;
    font-family: "Encode Sans Expanded";
    font-size: 21px;
    font-style: normal;
    font-weight: 500;

    
  }
  .addAccountPopupTitle2{
    color: #787777 ;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    width: 402px;
    height: 63px;
    margin-bottom: 20px ;
  }
  .addAccountForm {
    display: flex;
    flex-direction: column;
  }
  
  .addAccountFormGroup {
    margin-bottom: 20px;
    width: 316px;
height: 30px;
color: #787777;

font-family: "Encode Sans Expanded";
font-size: 12px;
font-style: normal;
font-weight: 300;
    display: flex;
    flex-direction: column;
  }
  
  .addAccountFormGroup2 {
    margin-bottom: 20px;
    color: #787777;

    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
  }
  .addAccountFormGroup2 input {
    width: 61px;
    height: 24px;
    margin-right: 31px;
  }
  .addAccountFormLabel {
    color: #063565;

    text-align: right;
    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
  }
  
  .addAccountFormInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 316px;
height: 30px;
flex-shrink: 0;
  }
  
  .addAccountFormActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
  }
  
  .addAccountCancelButton,
  .addAccountSubmitButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addAccountCancelButton {
    background-color: #ccc;
    color: #fff;
    margin-right: 10px;
    border-radius: 6px !important;

 
        width: 169px;
    height: 35.062px;
    
    font-family: "Encode Sans Expanded";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  
  .addAccountSubmitButton {

    border-radius: 6px !important;

background: #F90;
    width: 169px;
height: 35.062px;

font-family: "Encode Sans Expanded";
font-size: 14px;
font-style: normal;
font-weight: 500;

  }
  