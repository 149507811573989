.archivio-detail-filter-and-results-archivio-detail-filter-and-results {
  width: 673px;
  height: 666.0791015625px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-group96 {
  top: 0px;
  left: 6px;
  width: 158px;
  height: 68px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text01 {
  color: rgba(0, 0, 0, 1);
}
.archivio-detail-filter-and-results-frame1 {
  top: 34px;
  left: 0px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.archivio-detail-filter-and-results-text03 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.archivio-detail-filter-and-results-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
}

.no-data-found {
  font-family: "Encode Sans Expanded";
  align-self: center;
  margin-top: 30px;
  margin-left: 480px !important;
  white-space: nowrap;
}
@media  screen and (max-width: 1408px) {
  .no-data-found {
    font-family: "Encode Sans Expanded";
    align-self: center;
    margin-top: 30px;
    margin-left: -460px !important;
    white-space: nowrap;
  }
}
@media  screen and (max-width: 720px) {
  .no-data-found {
    font-family: "Encode Sans Expanded";
    align-self: center;
    margin-top: 30px;
    margin-left: 0px !important;
    white-space: nowrap;
  }
}
.archivio-detail-filter-and-results-group95 {
  top: 92px;
  left: 0px;
  width: 673px;
  height: 574.0791015625px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -460px;
}
.archivio-detail-filter-and-results-frame66 {
  top: 0px;
  left: 0px;
  width: 673px;
  display: flex;
  padding: 24px;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
}
.archivio-detail-filter-and-results-frame65 {
  width: 553px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 16px;
  flex-direction: column;
}
.archivio-detail-filter-and-results-text05 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text06 {
  color: rgba(0, 0, 0, 1);
}
.archivio-detail-filter-and-results-text08 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.archivio-detail-filter-and-results-frame5056 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz486 {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 8px;
}
.archivio-detail-filter-and-results-share13 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.archivio-detail-filter-and-results-group {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-vector {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector01 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector02 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector03 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector04 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.archivio-detail-filter-and-results-frame68 {
  cursor: pointer;
  left: 0px;
  width: 673px;
  display: flex;
  padding: 24px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 30px;
  cursor: auto;
}
.archivio-detail-filter-and-results-group66 {
  width: 232px;
  height: 174.0792694091797px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 16px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-rectangle21 {
  top: 0px;
  left: 9px;
  width: 223px;
  height: 159px;
  position: absolute;
  object-fit: cover;
}
.archivio-detail-filter-and-results-group24 {
  top: 143.9207305908203px;
  left: 0px;
  width: 126.82051086425781px;
  height: 30.158536911010742px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 153, 0, 1);
}
.archivio-detail-filter-and-results-text12 {
  top: 6.959668159484863px;
  left: 12.372712135314941px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 11.599438667297363px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.archivio-detail-filter-and-results-shield-protected41 {
  top: 6.959668159484863px;
  left: 102.07508850097656px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.archivio-detail-filter-and-results-group1 {
  top: 0px;
  left: 0px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-vector05 {
  top: 2.1731228828430176px;
  left: 2.577623128890991px;
  width: 10px;
  height: 11px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector06 {
  top: 6.524679183959961px;
  left: 5.9609293937683105px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.archivio-detail-filter-and-results-frame5041 {
  width: 314px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 16px;
  flex-direction: column;
}
.archivio-detail-filter-and-results-text14 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text16 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.archivio-detail-filter-and-results-frame5057 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.archivio-detail-filter-and-results-group67 {
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 8px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz487 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz488 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.archivio-detail-filter-and-results-share131 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.archivio-detail-filter-and-results-group2 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-vector07 {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector08 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector09 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector10 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector11 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}

.archivio-detail-filter-and-results-cancel {
  top: -20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
}

.archivio-detail-filter-and-results-frame651 {
  top: 433.0791015625px;
  left: 0px;
  width: 673px;
  display: flex;
  padding: 24px;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
}
.archivio-detail-filter-and-results-frame652 {
  width: 553px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 16px;
  flex-direction: column;
}
.archivio-detail-filter-and-results-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text21 {
  color: rgba(0, 0, 0, 1);
}
.archivio-detail-filter-and-results-text23 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.archivio-detail-filter-and-results-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.archivio-detail-filter-and-results-frame5058 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz489 {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 8px;
}
.archivio-detail-filter-and-results-frame5038 {
  height: 93px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
  justify-content: space-between;
}
.archivio-detail-filter-and-results-share132 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.archivio-detail-filter-and-results-group3 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.archivio-detail-filter-and-results-vector12 {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector13 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector14 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector15 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.archivio-detail-filter-and-results-vector16 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.archivio-detail-filter-and-results-image2 {
  width: 23px;
  height: 23px;
  position: relative;
  border-color: transparent;
}
.archivio-detail-filter-and-results-root-class-name {
  align-self: flex-start;
}


@media(max-width: 1408px) {
  .archivio-detail-filter-and-results-archivio-detail-filter-and-results {
width: 100%;
margin-top: 0px;
  }
  .archivio-detail-filter-and-results-group95 {
    left: 158px;
    margin-left: 0px;
  }
  }
  @media(max-width: 714px) {
.component-head-archivio-line16 {
  top: 172px;
}
.component-head-archivio-rectangle69 {

}
.archivio-detail-filter-and-results-rectangle21 {
  border-radius: 7px;
}
.component-head-archivio-text07 {
 
}
.archivio-detail-filter-and-results-text16 {
  font-size: 16px;
}
.archivio-detail-filter-and-results-frame68 {
  width: 500px;
  height: 117px;
  margin-left: 0px;
}
.archivio-detail-filter-and-results-group66 {
  width: 83px;
  height: 83px;
  margin-right: 70px;
  margin-top: -7px;
  border-radius: 7px;
}
.archivio-detail-filter-and-results-frame5041 {
  margin-right: -21px;
}
.archivio-detail-filter-and-results-text18 {
  display: none;
}
.archivio-detail-filter-and-results-text14 {
  font-size: 12px;
}
.archivio-detail-filter-and-results-group95 {
  position: relative;
  align-items: center;
  border-radius: 7px;
  margin-top: 200px
}
.archivio-detail-filter-and-results-group24 {
  display: none;
}
.archivio-detail-filter-and-results-rectangle21 {
  width: 83px;
  height: 83px;
}
    }

    @media(max-width: 457px) {
      .archivio-detail-filter-and-results-frame68 {
    width: 350px;
    margin-bottom: -30px;
    margin-left: 20px;
    margin-top: 50px;
      }
      .archivio-detail-filter-and-results-frame5041 {
        width: 220px;
      }
      .archivio-detail-filter-and-results-group66 {
        width: 60px;
        height: 60px;
        margin-right: 86px;
        margin-top: 5px;
      }
      .archivio-detail-filter-and-results-rectangle21 {
        width: 60px;
        height: 60px;
      }
    }