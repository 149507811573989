.login-pop-up-container {
  width: 664px;
  height: 542px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.login-pop-up-frame5147 {
  width: 390px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.login-pop-up-frame5145 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 31px;
}
.login-pop-up-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame34 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 31px;
  flex-direction: column;
}
.login-pop-up-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.login-pop-up-text05 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame31 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.login-pop-up-text07 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame35 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 31px;
  flex-direction: column;
}
.login-pop-up-frame331 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.login-pop-up-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame311 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.login-pop-up-text11 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame13 {
  display: flex;
  padding: 10px 14px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  margin-bottom: 31px;
  background-color: rgba(255, 153, 0, 1);
  cursor: pointer;
}
.login-pop-up-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.login-pop-up-frame5146 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  cursor: pointer;
}
.login-pop-up-text15 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
