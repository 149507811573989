.pop-up-save-link2-container {
    width: 515px;
    height: 266px;
    display: flex;
    position: fixed;
    align-items: flex-start;
    flex-direction: column;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
}

.pop-up-save-link2-salvataggiolink {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    position: relative;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612);
    align-items: center;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 1);
}

.pop-up-save-link2-frame122 {
        display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 292px;
    flex-direction: column;
}

.pop-up-save-link2-group121 {
    width: 479px;
    height: auto;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
    flex-direction: row;
    justify-content: flex-start;
}

.pop-up-save-link2-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
}

.text-on-popup{
    margin-top: 70px;
    font-size: 12px;
    font-family: "Encode Sans Expanded";
}

.pop-up-save-link2-frame119_active {
    background: #ffA50030;
    border-radius: 10px;
}

.pop-up-save-link2-frame119 {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.pop-up-save-link2-folder-blank3 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 26px;
}

.pop-up-save-link2-text2 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-save-link2-frame120 {
    top: 70px;
    left: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: transparent;
}

.pop-up-save-link2-folder-blank4 {
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 26px;
}

.pop-up-save-link2-text4 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-save-link2-frame13 {
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(255, 153, 0, 1);
}

.pop-up-save-link2-text6 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 12px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.pop-up-save-link2-deletedisabled2 {
    width: 24px;
    height: 24px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    cursor: pointer;
}

.pop-up-save-link2-group {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.pop-up-save-link2-vector {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    position: absolute;
}

.pop-up-save-link2-vector1 {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    position: absolute;
}
