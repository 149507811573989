.data-wallet-enterprise-header-container {
  width: 666px;
  height: 192px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.selected-tab {

  color: #ff9900; /* White text color */
  font-weight: 600;

}
.TextAds {
  font-family: "Encode Sans Expanded";
  cursor: pointer;
  font-size: 15px;
 
}
.data-wallet-enterprise-header-filtri-e-scritta-wallet-enterprise {
  width: 100%;
  height: 205px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 110px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-top: 28px;
}

.data-wallet-enterprise-header-frame5017 {
  top: 130.724609375px;
  left: 510px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: flex-end;
  background-color: rgba(255, 153, 0, 1);
  cursor: pointer;
}
.data-wallet-enterprise-header-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: right;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  white-space: nowrap
}
.data-wallet-enterprise-header-text02 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  margin-left: 78px;
}
.data-wallet-enterprise-header-text04 {
  top: 44.724609375px;
  left: 80.5px;
  color: rgba(81, 81, 81, 1);
  width: 417px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.data-wallet-enterprise-header-text05 {
  font-weight: 400;
}
.data-wallet-enterprise-header-text09 {
  font-weight: 700;
}
.data-wallet-enterprise-header-frame5019 {
  top: 137.44921875px;
  left: 75px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;

  }
.data-wallet-enterprise-header-frame52 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 24px;
}
.data-wallet-enterprise-header-rectangle890{
  width: 18px;
  height: 18x;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
  margin-top: 14px;
}
.data-wallet-enterprise-header-rectangle89 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.data-wallet-enterprise-header-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-header-frame53 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 24px;
}
.data-wallet-enterprise-header-rectangle891 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.data-wallet-enterprise-header-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-header-frame54 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-header-rectangle892 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.data-wallet-enterprise-header-text15 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-header-padlock1 {
  top: 42.724609375px;
  left: 7px;
  width: 64px;
  height: 64px;
  position: absolute;
  border-color: transparent;
}

