.stripe-payment-plan-container {
  width: 664px;
  height: 752px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.stripe-payment-plan-frame5180 {
  width: 404px;
  height: auto;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.stripe-payment-plan-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame5181 {
  width: 284px;
  height: auto;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.stripe-payment-plan-text02 {
  color: rgba(121, 121, 121, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame5187 {
  width: 389px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.stripe-payment-plan-frame5186 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 32px;
  flex-direction: column;
}
.stripe-payment-plan-frame5183 {
  /*display: flex;*/
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.stripe-payment-plan-frame32 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.stripe-payment-plan-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame31 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.stripe-payment-plan-text06 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame331 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.stripe-payment-plan-frame332 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame311 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 153, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 246, 232, 1);
}
.stripe-payment-plan-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame34 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}
.stripe-payment-plan-frame333 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame312 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.stripe-payment-plan-text14 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame5182 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.stripe-payment-plan-frame35 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 6px;
  flex-direction: column;
}
.stripe-payment-plan-frame334 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame313 {
  width: 200px;
  height: 54px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.stripe-payment-plan-text18 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame36 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 6px;
  flex-direction: column;
}
.stripe-payment-plan-frame335 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame314 {
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.stripe-payment-plan-text22 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame37 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.stripe-payment-plan-frame336 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.stripe-payment-plan-text24 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame315 {
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.stripe-payment-plan-text26 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.stripe-payment-plan-frame13 {
  display: flex;
  padding: 10px 14px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(255, 153, 0, 1);
  cursor: pointer;
}
.stripe-payment-plan-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
