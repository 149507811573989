.profile-side-container {
  flex: 0 0 auto;
  /*width: 250px;*/
  height: 44px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: -50.5px;
  gap: 20px;
}

.switch-side-container {
  flex: 0 0 auto;
  /*width: 250px;*/
  height: 44px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-top: 73px;
  background-color: orange;
}
.profile-side-profile-name-div {

  /*width: 70%;*/
  height: 44px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
  gap: 0 !important;
}
.profile-side-name-surname {

  font-size: 16px;
  font-style: normal;
  text-align: right;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 400;
  color: white;
}
.profile-side-username {
  color: #797979;
  width: var(--dl-size-size-large);
  font-size: 14px;
  display: none;
  margin-top: 5px !important;
  margin-right: 10px;
  text-align: left;

  font-weight: 500;
}
.profile-side-profile-pic {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-color: #b7b7b7;
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://i.ibb.co/QX0XxG4/user.png");
}
.profile-side-root-class-name {
  margin-top: 70px;
  margin-right: 74px;
}
.profile-side-root-class-name1 {
  margin-top: 70px;
  margin-right: 74px;
}

@media screen and (max-width: 1105px) {
  .profile-enterprise-frame5205 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .profile-side-username {
display: none;
  }
    .profile-side-container {
      flex: 0 0 auto;
      /*width: 250px;*/
      height: 44px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 0px !important; 
      margin-right: 12vw !important;
    }
 
    
  }