.login-platform-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Encode Sans Expanded';
}

.login-platform-form {
  width: 720px;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.login-platform-form h2 {
  margin-bottom: 20px;
  color: #2B3674;
  text-align: left;
  font-weight: 500;
  font-size: 28px;
}

.login-platform-form-group {
  margin-bottom: 20px;
}

.login-platform-form-group label {
  display: block;
  margin-bottom: 13px;
  color: #063565;
  font-weight: 500;
  font-size: 14px;
}

.login-platform-form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 49px;
}

.login-platform-forgot-password {
  display: block;
  text-align: right;
  margin-bottom: 20px;
  text-decoration: none;
  color: #063565 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.login-platform-submit-btn {
  padding: 10px;
  border: none;
  border-radius: 6px;
  background-color: #FF9900;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: 54px;
}

.login-platform-submit-btn:hover {
  background-color: darkorange;
}

.forgot-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.forgot-password-content h3 {
  margin-bottom: 20px;
  color: #2B3674;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
}

.forgot-password-form-group {
  margin-bottom: 20px;
}

.forgot-password-form-group label {
  display: block;
  margin-bottom: 10px;
  color: #063565;
  font-weight: 500;
  font-size: 14px;
}

.forgot-password-form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 40px;
}

.reset-password-btn {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #FF9900;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: 40px;
}

.reset-password-btn:hover {
  background-color: darkorange;
}