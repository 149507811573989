.rename {
  width: 165px;
  border: 1px solid orange;
}
.inputDisabled {
  width: 165px;
 
}
.inputDisabled {
  background: transparent;
}
.tuttecartcolumn {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  width: 100%;
  margin-left: -390px ;
  margin-top: 20px;
}
.tutte-le-cartelle-tutte-le-cartelle {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: 1057px;
  height: 100%;
  padding: 0;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 32px;
  justify-content: flex-start;
  background-color: transparent;
  margin-top: 32px;
}
.cartellegrids {
 display: flex;
 flex-direction: row;
}
.tutte-le-cartelle-text {

  color: rgba(0, 0, 0, 1);
  height: auto;

  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  margin-left: 252px;
}
.tutte-le-cartelle-frame30 {
  top: 40.5205078125px;
  left: 0px;
  display: flex;
  
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;

  flex-wrap: wrap;
}

.tutte-le-cartelle-group87 {
  width: 199px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  cursor: pointer;
}
.tutte-le-cartelle-rectangle6 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.tutte-le-cartelle-group86 {
  top: 16px;
  left: 16.00146484375px;
  width: 174.78173828125px;
  height: 155.78857421875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.tutte-le-cartelle-dots1 {
  top: 0px;
  left: 150.78173828125px;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 100;
}
.tutte-le-cartelle-frame5140 {
  top: 10.78857421875px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.tutte-le-cartelle-folder-blank1 {
  width: 56px;
  height: 56px;
  position: relative;
  margin-bottom: 17px;
}
.tutte-le-cartelle-text02 {
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 17px;
  text-decoration: none;
}
.tutte-le-cartelle-text04 {
  color: rgba(161, 161, 161, 1);
  width: 167px;
  height: auto;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.tutte-le-cartelle-group31 {
  top: 40.72021484375px;
  left: 429.56640625px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.tutte-le-cartelle-rectangle61 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.tutte-le-cartelle-dots11 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.tutte-le-cartelle-folder-blank11 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.tutte-le-cartelle-text06 {
  top: 109.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-text08 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-group32 {
  top: 40.72021484375px;
  left: 214.783203125px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.tutte-le-cartelle-rectangle62 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.tutte-le-cartelle-dots12 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.tutte-le-cartelle-folder-blank12 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.tutte-le-cartelle-text10 {
  top: 129.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-text12 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-group33 {
  top: 40.72021484375px;
  left: 644.349609375px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.tutte-le-cartelle-rectangle63 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.tutte-le-cartelle-dots13 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.tutte-le-cartelle-folder-blank13 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.tutte-le-cartelle-text14 {
  top: 129.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-text16 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-group34 {
  top: 40.72021484375px;
  left: 859.1328125px;
  width: 198.78314208984375px;
  height: 196.57696533203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.tutte-le-cartelle-rectangle64 {
  top: 0px;
  left: 0px;
  width: 199px;
  height: 197px;
  position: absolute;
}
.tutte-le-cartelle-dots14 {
  top: 16px;
  left: 166.78314208984375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.tutte-le-cartelle-folder-blank14 {
  top: 26.78857421875px;
  left: 71.39157104492188px;
  width: 56px;
  height: 56px;
  position: absolute;
}
.tutte-le-cartelle-text18 {
  top: 129.5771484375px;
  left: 16.0015869140625px;
  color: rgba(0, 0, 0, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tutte-le-cartelle-text20 {
  top: 157.5771484375px;
  left: 16.0015869140625px;
  color: rgba(161, 161, 161, 1);
  width: 167px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
img.close1 {
  position: absolute;
  top: 75px;
  right: 7px;
}
img.close2 {
  position: absolute;
  top: 3px;
  right: 7px;
}

@media screen and (max-width: 835px) {

    }