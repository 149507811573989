.power-search-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.provastilepower {
  margin-top: 6px !important;
}
@media screen and (max-width: 1104px) {
  .provastilepower {
    margin-top: -193px !important;
  }
}
.filtridesktopnew {
  width: 25px;
  align-self: left;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-left: -100px;
  position: relative;
  top: 0;
  left: 0;
}
.filtriavanzmobile2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  }
.power-search-containerArchivioDettaglio{
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
 
}
.power-search-containerArchivioDettaglio::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.filtriavanzmobile {
display: flex;
flex-direction: row;
justify-content: flex-start;
}
.filtrimobilebutton {
  margin-left: -32%;
cursor: pointer;
z-index: 1000;
display: none;
}
.overlaymenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 155px;
  background-color: rgb(255, 255, 255); /* Semi-transparent background */
  z-index: 2; /* Ensure it's on top */
  box-shadow: 0 2px 1px rgba(212, 212, 212, 0.5); /* White shadow */
}
@media screen and (max-width: 1106px) {
.overlaymenu {
  display: none;
}
  }
.power-search-container-principale {
  width: 100%;
  height: 287px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.power-search-container1 {
  width: 211px;   
  height: 306px;
  display: flex;
  margin-top: 30px;
  margin-left: -30px;
  align-items: flex-start;
  justify-content: center;
}
.nomenurightprofile {
  
visibility: hidden;
overflow: hidden;
margin-left: 50px;
}
.power-search-container2 {
  position: fixed;
  left: 200px;
  flex: 0 0 auto;
  width: 671px;
  height: 56px;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  z-index: 2;
}
@media(max-width: 1105px) {
  .power-search-container2 {
    flex: 0 0 auto;
    position: unset;
    width: 671px;
    height: 56px;
    display: flex;
    margin-top: 30px;
    align-items: flex-start;
  }
  .power-search-tabs-filters-div {
    margin-left: 20px;
    position: unset !important;
    flex: 0 0 auto;
    width: 433px;

    align-items: center;
    margin-right: 290px;
    margin-bottom: 100px;
    margin-top: -200px;

  }
  
}
.power-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 100px;
  left: 190px;
}
.video-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  margin-top: -169px;
  align-items: center;
  margin-right: 290px;
  flex-direction: column;
  justify-content: center;
}
.map-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  margin-top: -169px;
  align-items: center;
  margin-right: 290px;
  flex-direction: column;
  justify-content: center;
}
.social-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  margin-top: -150px;
  align-items: center;
  margin-right: 290px;
  flex-direction: column;
  justify-content: center;
}
.product-search-tabs-filters-div {
  flex: 0 0 auto;
  width: 433px;
  height: var(--dl-size-size-medium);
  display: flex;
  margin-top: -150px;
  align-items: center;
  margin-right: 290px;
  flex-direction: column;
  justify-content: center;
}
.power-search-results-div {
  width: 100%;
  min-height: 610px;
  display: flex;
  align-items: center;
  margin-left: -60px;
  flex-direction: column;
  justify-content: flex-start;
}
.power-search-results-divvideo {
  width: 100%;
  min-height: 610px;
  display: flex;
  align-items: center;
  margin-left: -20px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -60px;
}
.no_data_found_text {
  color: rgb(161, 161, 161);
  text-align: left;
  font-family: "Encode Sans Expanded";
  margin-top: 250px;
width: 100%;
margin-left: 835px;
}
@media screen and (max-width: 1104px) {
  .power-search-results-divvideo {

    width: 671px !important;
    margin-top: -200px;
 
  }
  .weagleaitext {
    display: none;
  }
  .video-search-result-container {
     width: 671px !important;
     margin-left: 0px !important;
  }
}
@media screen and (max-width: 1104px) {
  .no_data_found_text {
    color: rgb(161, 161, 161);
    text-align: center !important;
    font-family: "Encode Sans Expanded";
    margin-top: 250px;
  width: unset !important;
  margin-left: unset !important;
  }
}

.table{
  width: 100%;
margin-bottom: 1rem;
background-color: transparent;

}
.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
  
  
}

.video-search-btn {
  display: flex;
  width: 100%;
  margin-left: 800px;
} 

@media screen and (max-width: 1104px) {
.news-filter-group74 {
  margin-right: -64px !important;
}
.video-search-btn {
  display: flex;
  width: unset;
  margin-left: 0px;
} 

.power-search-results-div {
  justify-content: center;
  width: unset;
}
.news-search-result-list-news-maindiv {
  margin-left: 0px !important;
}
  .power-search-container-principale {
justify-content: center;
  }
.power-search-container2 {
  align-items: center;
  width: auto;
}
.power-search-tabs-filters-divNews {
  margin-right: 0px;
  margin-left: 0px;
align-items: flex-start;
width: 100%;
}
.tab-power-search-container {
  justify-content: flex-start;

}
.search-engine-selection-container {
  
}
.power-search-container {
  overflow: hidden;
}
.power-search-containerArchivioDettaglio{

overflow-x: hidden;
overflow-y: hidden;

}
.divsearchselectionscroll{

}
.powersearchhorizontaltab {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
}
@media screen and (max-width: 700px) {
  .news-search-result-list-news-maindiv {
width: 300px !important;
  }
  .news-search-result-list-container {
    margin-bottom:-50px !important;
  }
  .news-search-result-list-news-maindiv {
    height: 150px !important;
  }
  .news-search-result-list-title-news {
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .news-search-result-list-image {
    display: none;
  }
  .news-search-result-list-image-news-result {
    width: 83px !important;
    height: 83px !important;
    margin-top: 17px !important;
  }
  .news-search-result-loader-container {
    margin-left: -42px !important;
margin-top: -90px !important;
  }
  .news-search-result-list-link-news {
    margin-bottom: 5px !important;
    font-size: 12px !important;
  }
  .news-search-result-list-title-link-description-news {
    margin-left: 50px !important ;
  }
.news-search-result-list-image-result {
  width: 83px !important;
  height: 83px !important;
}
.news-search-result-list-description-news {
  display: none;
  
}
.news-search-result-list-title-link-description-news {
  width: 143px !important;
  margin-right: -30px !important;
}
}
@media screen and (max-width: 720px) {
  .categoryFilter {display: none !important;}
  .news-filter-frame1 {
    display: none !important;
  }
  .languageFilter {
    display: none !important;
  }

  .news-filter-group74 {
    margin-left: -200px !important;
  }
  .pop-up-save-link1-container {
width: 455px;
top: 10px;
  }
  .pop-up-save-link1-filtro-data {
    width: 350px;
  }
  .video-search-result-container {
    width: 450px !important;
    margin-left: 0px !important;
 }
 .video-search-result-video-result-div {
  width: 451px !important;
 }
}
@media screen and (max-width: 640px) {
  .pop-up-save-link2-container {
    width: 250px !important;
    height: 266px;
    display: flex;
    position: fixed;
    align-items: flex-start;
    flex-direction: column;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
}
.pop-up-save-link2-group121 {
  width: 220px !important;
}
  .news-filter-group74 {
    margin-left: -285px !important;
    width: 500px !important;
  }
  .pop-up-save-link1-container  {
    z-index: 10000000000;
    margin-left: -265px;
  }
  .filtrimobilebutton {
    display: flex;
  }
.news-filter-text02 {
  display: none;
}
 
  .power-search-tabs-filters-div {
   overflow-y: unset;

     
align-items: flex-start;
width: 100%;
  }
  .search-engine-selection-container {
    margin-left: 20px;
  }
  .power-search-tabs-filters-div {
    margin-right: 0px;
    margin-left: 0px;
  
  }
  .web-search-result-list-web-result-main-div {

  }
}
@media screen and (max-width: 713px) {

 

  .web-search-result-list-web-result-main-div {
    width: 500px;
    margin-left: 0px;
  }
  .web-search-result-list-web-result-main-div-archivio-dettaglio {
    width: 500px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 515px) {

 

  .web-search-result-list-web-result-main-div {
    width: 450px;
    margin-left: 0px;
  
  }
  .web-search-result-list-web-result-main-div-archivio-dettaglio {
    width: 500px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 457px) {

 
.web-search-result-list-container {
  margin-top: 75px;
 
}
  .web-search-result-list-web-result-main-div {
    width: 350px;
    margin-left: 20px;
  height: 200px;
  }
  .web-search-result-list-web-result-main-div-archivio-dettaglio {
    width: 350px;
    margin-left: 0px;
  height: 200px;
  }
  .pagination {
    margin: 50px 0 !important;
  }
}
