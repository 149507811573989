.side-bar-container {
  width: 152px;
  height: 292px;
  display: flex;
  position: fixed; /* Keeps the sidebar fixed on the screen */
  flex-direction: column;
  justify-content: flex-start;
  top: 30px; /* Adjusted to add 30px margin at the top */
  left: 0; /* Keeps the sidebar at the left edge of the viewport */
 z-index:29;

}
.segnalaemotoredivButton {
  position: fixed; /* Fixed positioning relative to the viewport */
  bottom: 20; /* Aligns the div to the bottom of the viewport */
  left: 0; /* Aligns the div to the left of the viewport */

  /* Add any additional styling such as background, padding, etc. */
}
@media screen and (max-height: 535px) {

  .segnalaemotoredivButton {
    display: none
  }
}

/* .side-bar-container::before {
  content: ""; 
  position: fixed; 
  top: 0;
  left: 0;
  width: 200px; 
  height: 100vh;
  background: linear-gradient(180deg, rgba(6,53,101,1) 0%, rgba(27,36,50,1) 100%); 
  -webkit-box-shadow: 5px 1px 12px 5px rgba(0,0,0,0.2); 
  box-shadow: 2px 1px 20px 0px rgba(0,0,0,0.1);
  z-index: -1; 
} */

@media screen and (max-width: 1105px) {


  .side-bar-container::before {
    position: fixed; /* Fixed position */
    top: 0; /* Set the top to 0 */
    left: 0; /* Set the left to 0 */
    height: 100%; /* 100% Full-screen height */
    width: 200px; /* Cover 50% of the viewport width. You can set it as per your requirement */
    background: unset; /* Background color */
    box-shadow: none;
  }
}
.side-bar-logo {
  color: #ff9900;
  font-size: 35px;
  font-style: normal;
  font-family: 'Encode Sans Expanded';
  font-weight: 700;
  margin-left: 29px;
}
.side-bar-power-search-div {
  flex: 0 0 auto;
  width: 100%;
  height: 39px;
  display: flex;
  margin-top: 52px;
  align-items: center;
  border-radius: 7px;
  background-color: #F1F1F1;
}
.side-bar-search-icon {
  width: 22px;
  object-fit: contain;

}
.side-bar-power-search-text {
  font-family: 'Encode Sans Expanded';

  color: white;
}
.side-bar-my-feed-div {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15.5px;
  align-items: center;
  border-radius: 7px;
}
.side-bar-feed-icon {
  width: 22px;
  object-fit: contain;

}
.side-bar-feed-iconChat { 
  width: 22px;

  object-fit: contain;

}
.side-bar-feed-text {
  opacity: 0.5;
  text-align: left;
  font-family: 'Encode Sans Expanded';

  white-space: nowrap;
}
.side-bar-archivio-main-div {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15.5px;
  align-items: center;
  border-radius: 7px;
  padding: 5px;
  align-content: center;
 
}
.side-bar-inactive {
  display: flex;
  padding: 8px;
  white-space: nowrap;
 justify-content: flex-start;
 text-align: left;

}
.active-background {
width: 200px;
height:50px;
background-color: rgba(255,255,255,0.1); /* Adjust hover color as needed */
cursor: pointer;

}
.side-bar-archivio-icon {
  width: 22px;
  object-fit: cover;

}
.side-bar-archivio-text {
  opacity: 0.5;
  font-family: 'Encode Sans Expanded';
 
}
.side-bar-data-walletmain-div {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15.5px;
  align-items: center;
  border-radius: 7px;
}
.side-bar-wallet-icon {
  width: 22px;
  object-fit: contain;

}
.side-bar-wallet-text {
  opacity: 0.5;
  font-family: 'Encode Sans Expanded';
 
}
.side-bar-welfare-main-div {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15.5px;
  align-items: center;
  border-radius: 7px;
}
.side-bar-welfare-icon {
  width: auto;
  object-fit: cover;

}
.side-bar-welfare-text {
  opacity: 0.5;
  font-family: 'Encode Sans Expanded';
 
}

