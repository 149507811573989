.plan-sign-up-enterprise {
    position: absolute;
    top: 57px;
    z-index: 1;
    background-color: rgb(251, 251, 251);
    width: 202px;
    height: 290px;
    bottom: 252px;
    left: 29px;
    display: flex;
align-items: flex-start;
border-color: transparent;
flex-direction: column;
box-shadow: 5px 5px 5px 5px rgba(0.10000000149011612, 0.10000000149011612, 0.10000000149011612, 0.10000000149011612);
border-color: rgba(217, 217, 217, 217);
    border-radius: 10px;
  }
  .listDropDown {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    cursor: pointer;
  }
  .mainEnterprice{
    margin: 12px;
  }
.datePicker {
    position: relative;
    display: inline-block;
    transition-duration: 100s;
}
.closeButton {
    position: absolute;
    right: 4px;
    top: 8px;

  }

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.1);
    padding: 10px 16px;
    z-index: 1;
    border-radius: 10px;
    cursor: pointer;
    top: 45px;
    font-family: "Encode Sans Expanded";
    font-size: 15px;
}

.datePicker .dropdown-content {
    padding: 0;
    background-color: #fff;
}

.dropdown-content div {
    margin: 16px 0;
}

.dropdown-content div:hover, .dropdown-content div.active {
    color: rgb(253,153,39);
    font-weight: bold;
}

