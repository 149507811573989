.feed-preview-container {
  flex: 0 0 auto;
  width: 329px;
  height: 473px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-bottom-width: 1px;
}
.feed-preview-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feed-preview-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.feed-preview-container03 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  margin-left: 24px;
  flex-direction: column;
}
.feed-preview-image {
  width: 40px;
  object-fit: cover;
}
.loaderFeed{
  border: 18px solid #f3f3f3;
  border-top: 18px solid rgb(253,153,39);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
}

.feed-preview-text {
  font-size: 14px;
  margin-top: 11px;
  font-family: "Encode Sans Expanded";
}
.feed-preview-image1 {
  width: 24px;
  object-fit: cover;
  margin-right: 15px;
}
.feed-preview-container04 {
  cursor: pointer;
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.feed-preview-container05 {
  flex: 0 0 auto;
  width: 205px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed-preview-text1 {
  color: #0021d1;
  height: 54px;
  font-size: 14px;
  text-align: left;
  font-family: "Encode Sans Expanded";
  margin-left: 18px;
}
.feed-preview-container06 {
  flex: 0 0 auto;
  width: 60px;

  display: flex;
  align-items: center;
  margin-right: 22px;
  justify-content: center;
}
.feed-preview-image2 {
  width: 100%;
  object-fit: cover;
}
.feed-preview-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.feed-preview-container08 {
  flex: 0 0 auto;
  width: 205px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed-preview-text2 {
  color: #0021d1;
  height: 54px;
  font-size: 14px;
  text-align: left;
  font-family: "Encode Sans Expanded";
  margin-left: 18px;
}
.feed-preview-container09 {
  flex: 0 0 auto;
  width: 89px;
  height: 54px;
  display: flex;
  align-items: center;
  margin-right: 22px;
  justify-content: center;
}
.feed-preview-image3 {
  width: 100%;
  object-fit: cover;
}
.feed-preview-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.feed-preview-container11 {
  flex: 0 0 auto;
  width: 205px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed-preview-text3 {
  color: #0021d1;
  height: 54px;
  font-size: 14px;
  text-align: left;
  font-family: "Encode Sans Expanded";
  margin-left: 18px;
}
.feed-preview-container12 {
  flex: 0 0 auto;
  width: 89px;
  height: 54px;
  display: flex;
  align-items: center;
  margin-right: 22px;
  justify-content: center;
}
.feed-preview-image4 {
  width: 100%;
  object-fit: cover;
}
.feed-preview-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  justify-content: flex-end;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.feed-preview-container14 {
  flex: 0 0 auto;
  width: 175px;
  height: 39px;
  display: flex;
  align-items: center;
  margin-right: 22px;
  border-radius: 7px;
  justify-content: center;
  background-color: 063565;
}
.feed-preview-text4 {
  font-size: 14px;
  font-style: normal;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  color: white
}
.feed-preview-root-class-name {
  margin-top: 1.5rem;
}
.deleteFeed {
  position: absolute;
  right: 0;
  top: 15px;
}
