 /* aiSettingsPrivacy.css */
.ai-settings-privacy {
    border-radius: 8px;
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 417px;
    height: 495px;
    font-family: "Encode Sans Expanded";
  }
  
  .ai-settings-privacy-header h2 {
    margin: 0 0 20px 0;
    color: #063565;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ai-settings-privacy-section3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */

  }
  
.ai-settings-privacy-section3 label {
  margin-top: -5; /* No extra margin */
  margin-left: 10px;
}

  .ai-settings-privacy-section p {
    margin: 15px 0;
    color: #787777;
   font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;

  }
  
  .ai-settings-privacy-checkboxes label {
    display: block;
      margin-top: -5; /* No extra margin */
    margin-bottom: 10px;
    font-size: 14px;
    color: #063565;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    margin-left: 10px;
  }
  
  .ai-settings-privacy-checkboxes input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .ai-settings-privacy-warning {
    margin-top: 10px;
    color: #787777;
    font-family: "Encode Sans Expanded";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  