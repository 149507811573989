/* contentAccess.css */
.content-access {
    border-radius: 8px;
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    height: 274px;
    width: 417px; /* Adjust width as needed */
    font-family: "Encode Sans Expanded";
  }
  
  .content-access-header h2 {
    margin: 0;
    color: #063565;
    font-family: "Encode Sans Expanded";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
  }
  .content-access-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
   
  }
  .content-access-body p {
    margin: 10px 0;
    color: #787777;
    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
   
  }
  
  .content-access-security-level {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .content-access-security-level label {
    margin-right: 10px;
    margin-left: 15px;
    font-size: 14px;
    color: #063565;
font-style: normal;
font-weight: 300;
line-height: normal;
  }
  
  .content-access-security-level select {
    padding: 1 5 1 5px;
    color: #063565;
    font-weight: 500;
margin-top: -15px;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
  
  .content-access-buttons button {
    border-radius: 6px;
    background:  #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    border: none;
    padding: 6px 10px 6px 10px;
    margin-right: 10px;
  
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    transition: background-color 0.3s;
    color: #063565;
font-family: "Encode Sans Expanded";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
  }
  
  .content-access-buttons button:hover {
    background: #ddd;
  }
  
  /* Add any additional styling as needed */
  /* contentAccess.css */

.content-access-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content-access-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 446px;
  width: 100%;
}

.content-access-popup h3 {
  margin-top: 0;
}

.content-access-popup-settings {
  margin-bottom: 20px;
}

.content-access-popup-setting {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.content-access-popup-setting label {
  margin-right: 10px;
  width: 120px;
}

.content-access-popup-dots {
  display: flex;
  margin-top: -10px;
}

.content-access-popup-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 5px;
  cursor: pointer;
}

.content-access-popup-dot.active {
  background-color: #007bff;
}

.content-access-popupbuttonGeneric {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.content-access-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.content-access-popup-content {
  background-color: #FBFAFB;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
}
.contentaccesslabel {
  white-space: nowrap;
  color: #063565;
font-family: "Encode Sans Expanded";
font-size: 14px;
font-style: normal;
font-weight: 400;

}
.content-access-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.content-access-popup-headerTitolo h3 {
  color: #063565;
  font-family: "Encode Sans Expanded";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.content-access-popup-headerTitolo {
  display: flex;
 flex-direction: column;
 gap: 12px;
 
}
.content-access-popup-headerTitolo h4 {
  color: #000;
  font-family: "Encode Sans Expanded";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
 
}
.content-access-popup-close {
  background-color: unset;

  color: 
  #063565;
  border: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content-access-popup-settings {
  margin-bottom: 20px;
}

.content-access-popup-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.content-access-popup-dots {
  display: flex;
}

.content-access-popup-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 5px;
  cursor: pointer;
}

.content-access-popup-dot.active {
  background-color: #787777;
}

.content-access-popup-whitelist {
  display: flex;
  margin-bottom: 10px;
}

.content-access-popup-whitelist input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  color: #787777;

font-family: "Encode Sans Expanded";
font-size: 12px;

}

.content-access-popup-whitelist button {
  padding: 8px 8px;

  background: #FFE299;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #063565;
font-family: "Encode Sans Expanded";
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 181.818% */
}

.content-access-popup-whitelist-list-container {
  max-height: 500px;
  min-height: 500px;
  
  overflow-y: auto;
  overflow-x: hidden;
}
    /* Style the scrollbar (for Webkit browsers) */
    .content-access-popup-whitelist-list-container::-webkit-scrollbar {
      width: 7px;
    
    }
    
    .content-access-popup-whitelist-list-container::-webkit-scrollbar-track {
      background: #f1f1f1; /* Track color */
      border-radius: 7px;
    }
    
    .content-access-popup-whitelist-list-container::-webkit-scrollbar-thumb {
      background: #888; /* Handle color */
      border-radius: 7px;
    }
    
    .content-access-popup-whitelist-list-container::-webkit-scrollbar-thumb:hover {
      background: #555; /* Handle color on hover */
    }
.content-access-popup-whitelist-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.content-access-popup-whitelist-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.content-access-popup-remove {
  background-color: #EE5D50 !important;
  color: #fff;
 font-size: 14px;

  border-radius: 100% !important;
width: 20px;
height: 20px;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.content-access-popuptitle  {
font-family: "Encode Sans Expanded";
gap: 12px;
display: flex;
flex-direction: column;
}
.content-access-popuptitle h3  {
  font-family: "Encode Sans Expanded";
  color: #063565;
  font-size: 21px;
font-style: normal;
font-weight: 500;
  }
  .content-access-popuptitle h4  {
    font-family: "Encode Sans Expanded";
    color: #787777;

    font-family: "Encode Sans Expanded";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    }