






















.tab-power-search-container {
  flex: 0 0 auto;
  width: 433px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;

}
.tab-power-search-web-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-web-tab-text {
  font-size: 15px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 400;
}
.tab-power-search-selection-underline {
  width: 37px;
  margin-top: 5px;
  object-fit: cover;
}
.tab-power-search-notizie-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-notizie-tab-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
}
.tab-power-search-aziende-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-aziende-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
}
.tab-power-search-video-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-video-tab-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
}
.tab-power-search-social-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-social-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
}
.tab-power-search-prodotti-tab-div {
  flex: 0 0 auto;
  width: 16.66%;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tab-power-search-prodotti-text {
  font-size: 15px;
  font-family: "Encode Sans Expanded";
}

