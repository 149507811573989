.data-wallet-enterprise-container {
  width: 673px;
  height: 903px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.selezionatuttobutton {
  top: 60;
  left: 20;
   position: relative;
   font-family: "Encode Sans Expanded";
   cursor: pointer;
   border-width: 0 0 1.4 0;
   border-color: #ff9900;
   z-index: 10;
}
.power-search-containerDataWallet {
  width: 100%;
  display: flex;

  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;


}
@media screen and (max-width: 702px) {
  .data-wallet-enterprise-container {

  
  }
  .selezionatuttobutton {
 
    top: -15;
    left: 20;
     position: relative;
     font-family: "Encode Sans Expanded";
     cursor: pointer;
     border-width: 0 0 1.4 0;
     font-size: 15px;
     border-color: #ff9900;
  }
.power-search-containerDataWallet {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;


}

}