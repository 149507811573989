.dashboard-date-picker {
    position: relative;
    display: flex;
flex-direction: column;
    border-radius: 4px;
    padding: 10px;
    user-select: none;
    font-family: "Encode Sans Expanded";
    margin-right: 35px;
    align-items: flex-end;
  }
  .dashboard-date-picker2 {
    position: relative;
    display: flex;
flex-direction: column;
    border-radius: 4px;
    padding: 10px;
    user-select: none;
    font-family: "Encode Sans Expanded";
    margin-right: -60;
    align-items: flex-end;
    z-index: 1;
  }
  .date-display {
    
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.60);
font-family: "Encode Sans Expanded";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
white-space: nowrap;

  }
  .datessourcetimerangediv {
    display: flex;
    gap: 28px;
  }
  .toggle-button {
white-space: nowrap;

    color: #000;
    margin-top: 5px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.60);
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .date-range-popup {
    position: absolute;
    top: 50;
    left: 67;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100px;
    max-width: 100px;
    max-height: 100px;
    overflow-y: scroll;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    display: none;
  }
  
  .date-range-popup.open {
    display: block;
    
  }
  .date-range-popup2 {
    position: absolute;
    top: 50;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
  }
  
  .date-range-popup2.open {
    display: block;
  }
  .date-range-popup::-webkit-scrollbar {
    width: 7px;
  
  }
  
  .date-range-popup::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 7px;
  }
  
  .date-range-popup::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 7px;
  }
  
  .date-range-popup::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }
  .date-range-option {
    padding: 5px;
    cursor: pointer;
    color: #000;

    cursor: pointer;
    color: rgba(0, 0, 0, 0.60);
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .date-range-option:hover {
    background-color: #f0f0f0;
  }
  
  .date-selected {
    font-weight: bold;
  }
  