/* newmenudesktop.css */
.menudesktop {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
}

.horizontal-line {
  position: fixed;
  top: 100%; /* Position it right below the .menudesktop */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black; /* Change to desired color */
}

.menu-container {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  
  .menu-icon {
    cursor: pointer;
    font-size: 24px;
    color: #333;
    transition: color 0.3s;
    
  }
  
  .menu-icon:hover { 
    color: #007BFF;
  }
  
  .menu-content {
    display: block;
    position: absolute;
    top: 50px; /* Adjust the top position to align with the icon */
    right: 0;
    background-color: #fff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 8px;
    animation: fadeIn 0.3s;
    padding: 8px 0;
  }
  
  .menu-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-content ul li {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s;
  }
  
  .menu-content ul li:hover {
    background-color: #f1f1f1;
  }
  
  .menu-content ul li button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }
  
  .menu-content ul li button img {
    margin-right: 12px;
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }