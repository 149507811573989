
.switch-user-container{
  width: 100%;
  height: 300px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 2000;
}

.share-popup-container {
  position: absolute;
  top: 230px;
    width: 532px;
    height: 261px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .share-popup-condividi {
    width: 100%;
    height: 300px;
    display: flex;
    overflow: hidden;
    position: relative;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.10000000149011612) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2000;
  }
  .share-popup-frame5219 {
    top: 32px;
    left: 31px;
    width: 469px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .share-popup-frame5216 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 33px;
    flex-direction: column;
  }
  .share-popup-text {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 20px;
    align-self: auto;
    font-style: Bold;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 24px;
    text-decoration: none;
  }
  .share-popup-frame5215 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
  }
  .share-popup-frame5214 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 109px;
    flex-direction: column;
  }
  .share-popup-text02 {
    color: rgba(121, 121, 121, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 4px;
    text-decoration: none;
  }
  .share-popup-frame5213 {
    display: flex;
    padding: 9px 11px;
    position: relative;
    align-items: center;
    border-color: rgba(217, 217, 217, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
  }
  .share-popup-frame5212 {
    width: 272px;
    display: flex;
    padding: 4px 11px;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 7px;
    flex-direction: column;
    background-color: rgba(243, 243, 243, 1);
  }
  .share-popup-frame5211 {
    display: flex;
    position: relative;
    align-items: center;
    border-color: transparent;
  }
  .share-popup-text04 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 10px;
    margin-bottom: 0;
    text-decoration: none;
  }
  .share-popup-group4 {
    width: 6px;
    height: 6px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .share-popup-vector {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .share-popup-vector1 {
    top: 0px;
    left: 0px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .share-popup-frame13 {
    display: flex;
    padding: 14px;
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(255, 153, 0, 1);
    margin-left: 15px;
  }
  .share-popup-text06 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .share-popup-frame5218 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    flex-direction: column;
  }
  .share-popup-text08 {
    color: rgba(121, 121, 121, 1);
    height: auto;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 8px;
    text-decoration: none;
  }
  .share-popup-frame5217 {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
  }
  .share-popup-text10 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
  }
  .share-popup-frame5220 {
    top: 219.5px;
    left: 249px;
    width: 100px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: transparent;
  }
  .share-popup-document-content-copy1 {
    width: 24px;
    height: 24px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 8px;
  }
  .share-popup-group {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
  }
  .share-popup-vector2 {
    top: 3px;
    left: 8px;
    width: 13px;
    height: 15px;
    position: absolute;
  }
  .share-popup-vector3 {
    top: 8px;
    left: 10px;
    width: 6px;
    height: 6px;
    position: absolute;
  }
  .share-popup-vector4 {
    top: 8px;
    left: 13px;
    width: 3px;
    height: 3px;
    position: absolute;
  }
  .share-popup-vector5 {
    top: 8px;
    left: 3px;
    width: 13px;
    height: 13px;
    position: absolute;
  }
  .share-popup-text12 {
    color: rgba(255, 153, 0, 1);
    height: auto;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: underline;
  }

  @media screen and (max-width: 710px) {
.share-popup-container {
  width: 432px;
  position: unset;
}
 

  }

  @media screen and (max-width: 468px) {
    .share-popup-container {
      width: 296px;
    }
     .share-popup-text {
      text-align: left;
      width: 200px;
     }
     .share-popup-frame5215 {
      align-items: flex-start;
      flex-direction: column;
     }
    .share-popup-frame5219 {
      width: 200px;
    }
    .share-popup-frame5216 {
      width: 200px;
    }
    .share-popup-frame5215 {
      width: 200px;
    }
    .user-sign-up-frame31 {
    
    }
    .share-popup-frame13 {
      margin-left: 0px;
    }
      }
    