.date-filter-video-date-filter-video {
  width: 77px;
  height: 34px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: relative;
  max-width: 77px;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 7px;
  margin-bottom: 0;
  justify-content: center;
  background-color: rgba(241, 241, 241, 0);
margin-top: 15px;
margin-left: -320px;
}
.date-filter-video-datefilter-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.date-filter-video-dropdownicon {
  width: 8px;
  height: 4px;
  position: relative;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
