.user-sign-up-frame5143 {
  width: 388px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}
.user-sign-up-frame32 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 29px;
  flex-direction: column;
}
.user-sign-up-frame5142 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.user-sign-up-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.user-sign-up-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame31 {
  width: 388px;
  display: flex;
  padding: 16px 16px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.user-sign-up-text02 {
  color: rgb(0, 0, 0);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame331 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 29px;
  flex-direction: column;
}
.user-sign-up-frame332 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.user-sign-up-text03 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame311 {
  width: 388px;
  display: flex;
  padding: 16px 16px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 153, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(255, 246, 232, 1);
}
.user-sign-up-text05 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame34 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 29px;
  flex-direction: column;
}
.user-sign-up-frame333 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.user-sign-up-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame312 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.user-sign-up-text09 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame35 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 29px;
  flex-direction: column;
}
.user-sign-up-frame334 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.user-sign-up-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame313 {
  width: 388px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.user-sign-up-text13 {
  color: rgba(161, 161, 161, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.user-sign-up-frame13 {
  display: flex;
  padding: 10px 14px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  margin-bottom: 29px;
  background-color: rgba(255, 153, 0, 1);
}

.message-signup-form{
  font-family: "Encode Sans Expanded";
  color: rgba(255, 153, 0, 1);
  margin: 12px 0px;
  text-align: center;
  font-weight: bold;
}
.user-sign-up-text15 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
}
.user-sign-up-text17 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
  cursor: pointer;
}
