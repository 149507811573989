.feed-detail-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
}
.aziende-detail-container {
  display: flex;
 
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  /* margin-top: 200px; */
}
.feed-detail-back-button {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: flex-start;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 100px;
 margin-left: -360px;
  text-decoration: underline;
}
.feed-detail-back-button2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: flex-start;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 10px;
margin-top: -100px;
  text-decoration: underline;
}
.feed-detail-website-image-link {
  width: 217px;
  height: 75px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: flex-start;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-website-image {
  top: 0px;
  left: 0px;
  height: 50px;
  position: absolute;
  border-color: transparent;
}
.feed-detail-website-link {
  width: 500px;
  top: 55px;
  color: rgba(107, 107, 107, 1);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.feed-detail-feed-detail-filters {
  width: 247px;
  height: 34px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: flex-start;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  cursor: pointer;
}
.feed-detail-frame1 {
  top: 0px;
  left: 0px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.feed-detail-text02 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.feed-detail-vector2 {
  width: 8px;
  height: 4px;
  position: relative;
}
.feed-detail-frame4 {
  top: 0px;
  left: 137px;
  display: flex;
  padding: 7px 12px 8px 12px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.feed-detail-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.feed-detail-vector21 {
  width: 8px;
  height: 4px;
  position: relative;
}
.feed-detail-feed-detail-results {
  width: 673px;
  height: 699px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  cursor: pointer;
}
.feed-detail-feed-detail-card {
  width: 673px;
  display: flex;
  padding: 24px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 16px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
}
.feed-detail-feed-detail-card-image {
  width: 232px;
  height: 174.0792694091797px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 16px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  object-fit: cover;
}
.feed-detail-feed-image-src {
  top: 0px;
  left: 9px;
  width: 223px;
  height: 159px;
  position: absolute;
  object-fit: cover;
}
.feed-detail-fake-news-text {
  top: 143.9207305908203px;
  left: 0px;
  width: 126.82051086425781px;
  height: 30.158536911010742px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: 063565;
}
.feed-detail-fake-news-text1 {
  top: 6.959668159484863px;
  left: 12.372712135314941px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 11.599438667297363px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.feed-detail-shield-protected41 {
  top: 6.959668159484863px;
  left: 102.07508850097656px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group {
  top: 10px;
  left: 0px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: none;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector {
  top: 2.1731228828430176px;
  left: 2.577623128890991px;
  width: 10px;
  height: 11px;
  position: absolute;
}
.feed-detail-vector01 {
  top: 6.524679183959961px;
  left: 5.9609293937683105px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.feed-detail-link-title-descrdetail-feed {
  width: 346px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;

  flex-direction: column;

}
.feed-detail-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text08 {
  color: rgba(0, 0, 0, 1);
}
.feed-detail-text10 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.feed-detail-share13 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group1 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector02 {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector03 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector04 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector05 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.feed-detail-vector06 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.feed-detail-frame69 {
  width: 673px;
  display: flex;
  padding: 24px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 16px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
}
.feed-detail-group66 {
  width: 232px;
  height: 174.4060516357422px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 16px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-rectangle17 {
  top: 0px;
  left: 9px;
  width: 223px;
  height: 160px;
  position: absolute;
}
.feed-detail-group24 {
  top: 144.2475128173828px;
  left: 0px;
  width: 126.82051086425781px;
  height: 30.158536911010742px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 153, 0, 1);
}
.feed-detail-text14 {
  top: 6.959668159484863px;
  left: 12.372712135314941px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 11.599438667297363px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.feed-detail-shield-protected411 {
  top: 6.959668159484863px;
  left: 102.07508850097656px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group2 {
  top: 0px;
  left: 0px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector07 {
  top: 2.1731228828430176px;
  left: 2.577623128890991px;
  width: 10px;
  height: 11px;
  position: absolute;
}
.feed-detail-vector08 {
  top: 6.524679183959961px;
  left: 5.9609293937683105px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.feed-detail-frame5041 {
  width: 346px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 16px;
  flex-direction: column;
}
.feed-detail-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text17 {
  color: rgba(0, 0, 0, 1);
}
.feed-detail-text19 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text21 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.feed-detail-share131 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group3 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector09 {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector10 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector11 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector12 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.feed-detail-vector13 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.feed-detail-frame70 {
  width: 673px;
  display: flex;
  padding: 24px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
}
.feed-detail-group661 {
  width: 232px;
  height: 174.0793914794922px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 16px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-rectangle20 {
  top: 0px;
  left: 9px;
  width: 223px;
  height: 159px;
  position: absolute;
}
.feed-detail-group241 {
  top: 143.9208526611328px;
  left: 0px;
  width: 126.82051086425781px;
  height: 30.158536911010742px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 153, 0, 1);
}
.feed-detail-text23 {
  top: 6.959668159484863px;
  left: 12.372712135314941px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 11.599438667297363px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.feed-detail-shield-protected412 {
  top: 6.959668159484863px;
  left: 102.07508850097656px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group4 {
  top: 0px;
  left: 0px;
  width: 15.46591567993164px;
  height: 15.46591567993164px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector14 {
  top: 2.1731228828430176px;
  left: 2.577623128890991px;
  width: 10px;
  height: 11px;
  position: absolute;
}
.feed-detail-vector15 {
  top: 6.524679183959961px;
  left: 5.9609293937683105px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.feed-detail-frame50411 {
  width: 346px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 16px;
  flex-direction: column;
}
.feed-detail-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text26 {
  color: rgba(0, 0, 0, 1);
}
.feed-detail-text28 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.feed-detail-text30 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.feed-detail-share132 {
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.feed-detail-group5 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.feed-detail-vector16 {
  top: 9.332952499389648px;
  left: 3.9999921321868896px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector17 {
  top: 4.000002384185791px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector18 {
  top: 14.6659517288208px;
  left: 14.66604232788086px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.feed-detail-vector19 {
  top: 7.84999942779541px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.feed-detail-vector20 {
  top: 13.190000534057617px;
  left: 9.039999008178711px;
  width: 6px;
  height: 3px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .feed-detail-back-button2 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 16px;
    align-self: flex-start;
    font-style: Regular;
    text-align: left;
    font-family: "Encode Sans Expanded";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-bottom: 10px;
  margin-top: 0px;
  margin-left: 55px;
    text-decoration: underline;
  }
  .feedloader {
margin-left: 230px;
margin-bottom: 40px;
  }
  .feed-detail-container {
    margin-top: -60px;
    overflow-x: hidden;
  }
  .feed-container8 {
    width: 100%;
  }
 .feed-detail-feed-detail-card {
    width: 343px;
    height: 111px;
  display: flex;
  padding: 0px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 16px;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 1);
  
 }
 .feed-detail-link-title-descrdetail-feed {
  margin-right: -80px;
 }
 .feed-detail-feed-detail-results {
  align-items: center;
 }
 
 .feed-detail-feed-image-src {
  box-sizing: border-box;

  position: absolute;
  width: 83px;
  height: 83px;

  top: 14px;

  border: 1px solid #DADADA;
  border-radius: 7px;
 }
 .feed-detail-fake-news-text {
  display: none;
 }
 .feed-detail-text10 {
  font-size: 14px;
  padding-left: 50px;
  padding-right: 70px;
 }
 .feed-detail-link-title-descrdetail-feed {
  font-size: 12px;
  top: 13px;
 }
 .feed-detail-text12 {

display: none;
 }
 .feed-detail-text08 {
margin-left: 52px;

 }
 .feed-detail-group1 {
  margin-left: -3px;

 }
.feed-detail-back-button {
  margin-left: -340px;
}
.feed-detail-website-image-link {
  margin-left: 60px;
}
.feed-detail-feed-detail-results {
  align-self: center;
}
.feedDetail2 {

}
          }

          @media screen and (max-width: 685px) {
            .feed-detail-back-button {
     margin-left: -270px;
            }
          }

          @media screen and (max-width: 545px) {
            .feed-detail-back-button {
     margin-left: -230px;
            }
          }
          @media screen and (max-width: 470px) {
            .feed-detail-back-button {
     margin-left: -200px;
            }
          }
          @media screen and (max-width: 402px) {
            .feed-detail-back-button {
     margin-left: -160px;
            }
          }
          @media screen and (max-width: 325px) {
            .feed-detail-back-button {
     margin-left: -130px;
            }
          }