.video-search-result-container {
  width: 100%;
  /* height: 400px; */
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 440px;
}
.video-search-result-video-result-div {
  flex: 0 0 auto;
  width: 673px;
  height: 160px;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  justify-content: flex-start;
  background-color: #ffffff;
  margin-top: 8px;
}
.video-search-result-video-result-image {
  flex: 0 0 auto;
  width: 130px;
  height: 87px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #dadada;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-size: cover;
  
}
.video-search-result-video-result-link-title-description {
  flex: 0 0 auto;
  width: 439px;
  height: 112px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.video-search-result-video-result-link {
  font-size: 13px;
  font-family: "Encode Sans Expanded";
}
.video-search-result-video-result-title {
  color: #0021d1;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
}
.video-search-result-video-result-description {
  font-size: 15px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  line-height: 19px;
}
.video-search-result-video-result-icon-div {
  flex: 0 0 auto;
  width: 62px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.video-search-result-video-result-icon {
  width: 128px;
  object-fit: cover;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 720px) {
  .video-search-result-video-result-description {
  display: none;
  }
  .video-search-result-video-result-div {
    width: 560px;
  }
}

@media(max-width: 589px) {

  .video-search-result-video-result-image {
    margin-left: 10px;
  }
  .video-search-result-video-result-description {
  display: none;
  }
  .video-search-result-video-result-title {
    width: 180px;
  }
  .video-search-result-video-result-link {
    font-size: 10px;
    width: 10px;
  }
  .video-search-result-video-result-div {
    width: 343px;
    margin-left: 20px;
  }
  .video-search-result-video-result-icon {
    border-radius: 7px;
  }
  .video-search-btn {
    justify-content: center;
    align-items: center;
  }
  .bottone-generico1-container  {
    margin-right: 10px;
  }
}