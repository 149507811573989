.product-search-result-container {
  width: 100%;
  /* height: 400px; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
}
.product-search-result-product-main-div {
  flex: 0 0 auto;
  width: 673px;
  height: 206px;
  display: flex;
  align-items: center;
  border-color: #dadada;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.product-search-result-product-result-image {
  flex: 0 0 auto;
  width: 223px;
  height: 149px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #dadada;
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/rectangle%2014-300w.png");
}
.product-search-result-product-description-link-stars {
  flex: 0 0 auto;
  width: 346px;
  height: 158px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.product-search-result-product-link {
  font-size: 13px;
  font-family: "Encode Sans Expanded";
}
.product-search-result-product-title {
  color: #0021d1;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Encode Sans Expanded";
  font-weight: 500;
}
.product-search-result-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.product-search-result-reviews-stars {
  width: 70px;
  align-self: center;
  object-fit: cover;
}
.product-search-result-number-of-reviews {
  margin-top: 1px;
  margin-left: 6px;
}
.product-search-result-share-div {
  width: 74px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.product-search-result-share-icon {
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  object-fit: cover;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.product-search-result-product-price {
  font-size: 20px;
  font-style: normal;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
