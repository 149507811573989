.privacy-options-table-container {
  overflow-x: hidden; /* Ensures the table is scrollable horizontally on smaller screens */
  box-shadow: 0px 4px 4px 0px #00000040;
background-color: #FBFAFB;
font-family: "Encode Sans Expanded";
width: 1032px;
border-radius: 6px;
max-height: 500px;
}


.privacy-options-table {
  
  width: 100%;
  border-collapse: collapse; /* Removes the space between borders */
}
.status-point {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 auto;
}

.status-point.green {
  background-color: #4caf50;
}

.status-point.red {
  background-color: #f44336;
}

.privacy-options-table th {
  padding: 8px; /* Adds space around content in table cells */
  font-size: 11px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #063565;
  border-bottom: 1px solid #DFDFDF;
}
.privacy-options-table td {
  color: #4A4A4A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Encode Sans Expanded";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;

padding: 10px 98px 11px 16px;
align-items: center;
}

.privacy-options-table th {
 
  cursor: pointer; /* Indicates that headers can be interacted with, assuming future sorting functionality */
}

/* Highlight the selected row with a different background color */
.selected-row {
  background-color: #FFE299; /* Slightly darker grey to highlight the selected row */
}

/* Optional: Change the cursor for cells that can toggle features to indicate interactivity */
.privacy-options-table td {
  cursor: pointer; /* Indicates that the cell is clickable */
}

/* Improve the visibility of clickable elements */
.sort-icon {
  cursor: pointer;
}

/* Styling for hover effects to improve user experience */
.privacy-options-table tr:hover {
  background-color: #f5f5f5;
}

/* Styling to prevent text selection */
.privacy-options-table td,
.privacy-options-table th {
  user-select: none; /* Prevents text selection */
}
