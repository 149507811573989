.feed-container {
  width: 100%;
  display: flex;
  overflow: unset;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.digitaljournalmaindiv {
  margin-top: 100px;
}
.feed-container-principale {
  width: 100%;
  height: 167px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.feed-container1 {
  width: 20%;
  height: 306px;
  display: flex;
  margin-top: 55px;
  align-items: flex-start;
  justify-content: center;
}
.feed-container2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 1050px;
  height: 100%;
  padding: 0;
  position: relative;
  align-self: center;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: -100px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 32px;
  justify-content: flex-start;
  background-color: transparent;
  margin-top: 32px;

}
.feed-container8 {
  width: 730px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -50px;

}
.feed-footer{
  width: 1050px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
}
.feed-container3 {
  flex: 0 0 auto;
  width: 145px;
  height: 38px;
  display: flex;
  position: relative;
  margin-top: 42px;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
  background-color: #FF9900;
}
.feed-text {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: "Encode Sans Expanded";
}
.feed-container4 {
  flex: 0 0 auto;
  width: 726px;
  height: 100px;
  display: flex;
  margin-top: 64px;
  align-items: center;
  margin-bottom: 154px;
  justify-content: center;
  
}
.feed-container5 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  align-items: center;
  margin-right: 32px;
  flex-direction: column;
  justify-content: center;
}
.feed-image {
  width: 32px;
  object-fit: cover;
  margin-top: 50px;
}
.feed-text1 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
    margin-bottom: 100px;
}
.feed-container6 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  margin-top: 18px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.feed-image1 {
  width: 32px;
  object-fit: cover;
  margin-top: 50px;
}
.feed-text2 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
  margin-bottom: 100px;
}
.feed-container7 {
  flex: 0 0 auto;
  width: 220px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.feed-image2 {
  width: 32px;
  object-fit: cover;
  margin-top: 50px;
}
.feed-text3 {
  color: rgb(161, 161, 161);
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 16.94px;
  margin-bottom: 100px;
}
@media(max-width: 991px) {
  .feed-container3 {
    margin-top: 48px;
    margin-right: 0px;
  }
  .feed-text {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: "Encode Sans Expanded";
  }
}

.mio-feed{
    width: 100%;
    height: 56px;
    margin-top: 96px;
    font-family: "Encode Sans Expanded";
}
@media screen and (max-width: 1369px) {
  .mio-feed {
    width: 100%;
  }
}

.mio-feed >p{
  color: gray;
  width: 250px;
}
@media screen and (max-width: 1339px) {
  .feed-container2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 750px;
    align-items: center;
    justify-content: center;
  }

          }
          @media screen and (max-width: 1104px) {
            .digitaljournalmaindiv {
              margin-top: -50px;
            }

          }
          @media screen and (max-width: 1025px) {
            .feed-container2 {
              grid-template-columns: repeat(1, 1fr);
              grid-gap: 10px;
              width: 100%;
            }
          
          }
            @media screen and (max-width: 721px) {
              .digitaljournalmaindiv {
                margin-top: -80px;
              }
              .feed-container {
            overflow-x: hidden;
              }
              .feed-footer{
           
              }
                .feed-container4 {
                  flex-direction: column;
                  margin-top: 320px;
                }
                .feed-container5 {
                
               margin-right: 0px;
                }
                .feed-container7 {
                
                  margin-left: 0px;
                   }
                  .feed-text1 {
                    margin-bottom: 0px;
                  }  
                  .feed-text2 {
                    margin-bottom: 0px;
                  }    
                }