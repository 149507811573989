.btn-manageGroupModal {
    background-color: none !important;
    background: none;
    color: #063565;

}
.create-group-input {
    display: flex;
    justify-content: center;
    align-items: center;
border-radius: 6px;
  gap: 20px;
  font-size: 12px;
  min-height: 37px;
  margin-top: 10px;
}
.groupFlex {
    display: flex;
    flex-direction: column;
}
.managegroup-create-btn {
    background-color: #FFE299;

    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0px 2px 2px 0px #00000040;
font-size: 12px;
white-space: nowrap;
font-weight: 500;
color: #063565;

width: 120px;
height: 31px;
margin-top: 9px;
  }