/* accountListWidgets.css */
.search-bar-container {
    max-width: 207px; /* Adjust the width as needed */
    margin: 0 auto;
    font-family: "Encode Sans Expanded";
font-size: 10px;
font-weight: 300;
line-height: 22px;
letter-spacing: 0px;
text-align: left;

  }
  
  .search-inputAccountList {
    width: 207px;
    padding: 10px 20px;

    border: 2px solid #e6e6e6;
    border-radius: 6px;
    outline: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: border-color 0.3s;
  }
  
  .search-input::placeholder {
    color: #9e9e9e;
  }
  
  .search-input:focus {
    border-color: #0078d4;
  }
  
  /* Optional: Add an icon inside the search input */
  .search-inputAccountList {
    padding-left: 40px; /* Make room for the icon */
    background-image: url('/playground_assets/dashboardaccountlisticon.svg'); /* Replace with the path to your search icon */
    background-repeat: no-repeat;
    background-position: 12px center; /* Adjust the position as needed */
    background-size: 20px; /* Adjust the size as needed */
  }
  