.buttonSegnala {
  
        margin-left: 20px;
       
    
        width: 158px;
        height: auto;
        background: #FFFFFF;
        border: 2px solid  #E2E3E3;
        border-radius: 7px;
        font-family: "Encode Sans Expanded";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        color: #1D1D1B;
        padding: 10px 14px;
        cursor: pointer;
        white-space: nowrap;
        
        
}

.closepop {
    font-family: "Encode Sans Expanded";
    font-size: 30px;
    cursor: pointer;
}

.popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    }
    
    .popup {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px #333;
    }