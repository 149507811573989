.threatscomponentcontainer {
    display: flex;
    flex-direction: column;
    width: 447px; /* Adjust the width as needed */
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "Encode Sans Expanded", sans-serif;
    border-radius: 6px;
    height: 409px;
    
    overflow: hidden; /* Ensures the shadow doesn't get cut off */
  }
  
  .threatscomponentTitleDateDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  margin-top: 10px;
    
  }
  .threatscomponentTitleDateDiv2 {
    display: flex;
    justify-content: flex-end;
  }
  .threatscomponentTitle {
    color: #063565;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
  }
  
  .threatscomponentGraphDetailtext {
    color: #063565;
    font-size: 10px;
    font-weight: 400;
    margin: 4px 19px; /* Adjust margin to align with your design */
  }
  
  
  .doublegraphdivthreats {
    display: flex;

  margin-top: 10px;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    gap: 10px;
  border-bottom: 1px solid #EAEAEA;
  }
  .doublegraphdivthreats:last-child {

  border-bottom: none;
  }
  .doubledivtitledivtthreats {
    display: flex;
    justify-content: space-around;
  text-align: center;
  align-items: center;
   
  }
  .doublegraphdivc1threats {
    display: flex;
   
  flex-direction: column;
  

  
  
  
  }
  .doublegraphdivc2threats {
    display: flex;
width: 40%;

  }
  /* Styling for the individual charts within doublegraphdiv */
  .chart-container {
    flex: 1; /* This will make each chart take up equal space */
    padding: 0 10px; /* Gives some space between the charts */
  }
  
  /* Specific styles for the Doughnut and Line charts if needed */
  .doughnut-chart-container {
    width: 50%; /* Adjust the width as needed */
  }
  
  .line-chart-container {
    width: 50%; /* Adjust the width as needed */
  }
  .titlethreatschart {
    color: #787777;
    font-family: "Encode Sans Expanded";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  .numberthreatschart {
    color: #063565;
font-family: "Encode Sans Expanded";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 48px; /* 171.429% */
  }
  .percentagethreatschart {
    color: #787777;
font-family: "Encode Sans Expanded";
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 22px; /* 157.143% */
  }
  /* You might want to add media queries if you're dealing with responsiveness */
  