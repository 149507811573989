.loader-containerSearch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  margin-left: 440px;

}

.loaderSearch {
  border: 18px solid #f3f3f3;
  border-top: 18px solid rgb(253, 153, 39);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
  margin-bottom: 25px;
  margin-left: 270px;
}

.loader-textSearch {
  font-size: 16px;
  color: #333;
  font-family: "Encode Sans Expanded";

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (max-width: 1105px) {
  .loader-containerSearch {
    display: none !important;
  }
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (min-width: 1105px) {
  .loader-containerloaderSearchMobile {
    display: none !important;
  }
  
}