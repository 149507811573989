.h1weaglechat {
    color: #ff9900;

}
.lineadivchat {
    height: 1px; /* thickness of the line */
    background-color: rgba(0, 0, 0, 0); /* color of the line */
    width: 100%; /* fill the entire width */
    box-shadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  }
