.risktreatmentcontainer {
    display: flex;
    flex-direction: column;
    width: 447px; /* Adjust the width as needed */
    background: #FBFAFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "Encode Sans Expanded", sans-serif;
    border-radius: 6px;
    height: 270px;
    
    overflow: hidden; /* Ensures the shadow doesn't get cut off */
  }
  
  .risktreatmentTitleDateDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  margin-top: 10px;
    
  }
  .risktreatmentTitleDateDiv2 {
    display: flex;
    justify-content: flex-end;
  }
  .risktreatmentTitle {
    color: #063565;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
  }
  
  .risktreatmentGraphDetailtext {
    color: #063565;
    font-size: 10px;
    font-weight: 400;
    margin: 4px 19px; /* Adjust margin to align with your design */
  }
  
  
  .doublegraphdivRisk {
    display: flex;
   justify-content: space-around;
    margin: 20px;
  
  
    height: 100%;
  
  }
  .doubledivtitledivtRisk {
    display: flex;
    justify-content: space-around;
  text-align: center;
  align-items: center;
   
  }
  .doublegraphdivc1Risk {
    display: flex;
   
  
  width: 35%;
  
  
  
  }
  .doublegraphdivc2Risk {
    display: flex;

    width: 35%;
  
  }
  /* Styling for the individual charts within doublegraphdiv */
  .chart-container {
    flex: 1; /* This will make each chart take up equal space */
    padding: 0 10px; /* Gives some space between the charts */
  }
  
  /* Specific styles for the Doughnut and Line charts if needed */
  .doughnut-chart-container {
    width: 50%; /* Adjust the width as needed */
  }
  
  .line-chart-container {
    width: 50%; /* Adjust the width as needed */
  }
  
  /* You might want to add media queries if you're dealing with responsiveness */
  