
.sidebar-dashboard {
    width: 280px;
    height: 100vh;
    background: linear-gradient(180deg, rgba(6,53,101,1) 0%, rgba(27,36,50,1) 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjusted to space-between */
    align-items: center;
  }
  
  .sidebar-header-dashboard {
    text-align: center;
    padding: 20px;
    display: flex;
    gap: 7px;
    cursor: pointer;
    align-self: flex-start;
    margin-left: 15px;
  }
  .sidebarDashboardHeaderNameCompany {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;
   
  }
  .sidebar-avatar-dashboard {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-image: url("https://i.ibb.co/QX0XxG4/user.png");

  }
  
  .sidebar-username-dashboard {
    margin: 0;
    font-size: 18px;
    font-family: "Encode Sans Expanded", sans-serif;
    font-weight: 300;
  }
  
  .sidebar-company-dashboard {
    font-size: 12px;
    font-family: "Encode Sans Expanded", sans-serif;
    font-weight: 500;
  }
  
  .sidebar-nav-dashboard {
    width: 100%;
    flex-grow: 1; /* This will make it take up all available space */
    display: flex;
    flex-direction: column; /* Direction of flex items */
  }
  .LogoutDashboardButton {
    
      border-radius: 6px !important;
background: #F9F9F9;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
width: 99px;
height: 26.457px;
color: #09325D;
text-align: center;
font-family: "Encode Sans Expanded";
font-size: 12px;
font-style: normal;
font-weight: 500;
padding: 0 !important;
  }
  .sidebar-nav-item-dashboardnew {
    width: 280px;
   height: 50px;
    text-align: left;
    background-color: transparent;
    border: none;
    color: #1E2F6D;

    font-family: "Lexend Deca";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    border: none;
    border-radius: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    font-family: "Encode Sans Expanded";

  }
  .sidebar-nav-item-dashboard {
    width: 280px;
   height: 50px;
    text-align: left;
    background-color: transparent;
    border: none;
    color: #ffffff;

    font-family: "Lexend Deca";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    border: none;
    border-radius: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    font-family: "Encode Sans Expanded";

  }
  .sidebar-nav-item-dashboardMobile {
    width: 280px;
   height: 50px;
    text-align: left;
    background-color: transparent;
    border: none;
    color: #ffffff;

    font-family: "Lexend Deca";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    border-radius: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    font-family: "Encode Sans Expanded";

  }
  .sidebar-nav-item-dashboardMobile:hover {
    background-color: rgba(255,255,255,0.1); /* Adjust hover color as needed */
    cursor: pointer;
  }
  .sidebar-nav-item-dashboardMobile.active {
    background-color: rgba(255,255,255,0.1); /* Adjust hover color as needed */

  }
  .sidebar-nav-item-dashboard:hover {
    background-color: rgba(255,255,255,0.1); /* Adjust hover color as needed */
    cursor: pointer;
  }
  .sidebar-nav-item-dashboard.active {
    background-color: rgba(255,255,255,0.1); /* Adjust hover color as needed */

  }
  .sidebar-icon {
    margin-right: 12px;
   
  }
  .logoWeagleSideBarDashboard {
    margin-top: 12px;
    width: 86px;
  }
  .sidebar-separator {
    width: 246px;
    text-align: center;
    border: 0;
    height: 3px;
    background-color: #204A8C; /* Line color */

 
  }
  .sidebar-separatorBottom {
    width: 246px;
    border: 0;
    height: 3px;
    background-color: #204A8C; /* Line color */
    align-self: center; /* Center the separator */
    margin-bottom: 45px;
  }
  
  .sidebar-icon {

    
    margin-right: 12px;
  }
  
  /* Additional styling for icons can be added if necessary */
  