.account-list-widgetSettings {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    border-radius: 6px;
   
display: none;

    overflow-y: scroll;
    margin: 20px auto;
    font-family: "Encode Sans Expanded";
    width: 473px;
    max-height: 301px;
  }
    /* Style the scrollbar (for Webkit browsers) */
.account-list-widgetSettings::-webkit-scrollbar {
    width: 7px;
  
  }
  
  .account-list-widgetSettings::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 7px;
  }
  
  .account-list-widgetSettings::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 7px;
  }
  
  .account-list-widgetSettings::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }

  /* Main container and widget styling */
.account-list-widget {
    font-family: 'Arial', sans-serif;
    background-color: #FBFAFB;
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px #00000040;

    overflow-y: scroll;
    margin: 20px auto;
    font-family: "Encode Sans Expanded";
    width: 100%;
    max-height: 85vh;
  }

  /* Style the scrollbar (for Webkit browsers) */
.account-list-widget::-webkit-scrollbar {
  width: 7px;

}

.account-list-widget::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 7px;
}

.account-list-widget::-webkit-scrollbar-thumb {
  background: #888; /* Handle color */
  border-radius: 7px;
}

.account-list-widget::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}
  /* Header styling */
  .widget-headerAccount {
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    
  }
  
  .widget-headerAccount button {
    margin-left: 10px;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0);    color:#063565;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }
  
  .widget-header button:hover {
    background-color: #005a9e;
  }
  
  /* Table styling */
  .account-tableSettings {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  
  }
  .account-tableSettings th {
background-color: white !important;


  }
  .account-tableSettings thead {
    border-bottom: 2px solid #DFDFDF;


  }
  .buttonAccountListWithDivide {
 
    border-right: 1px solid #0000001F !important;
    border-left: 1px solid #0000001F !important;
    border-radius: 0px !important;
   
  }
  
  .account-tableSettings th, .account-tableSettings td {
    text-align: left;
    padding: 12px;

    
  }
  
  .account-tableSettings th {
    font-weight: 500;
    font-size: 11px;
    color: #063565;
    /* Ensure there's space for the icon */
    padding-left: 15; /* Adjust based on your icon's width */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    cursor: pointer;
  }
  
  .account-tableSettings th:not(:first-child)::before  {
    content: "";
    background-image: url('https://i.ibb.co/JdYgb2B/Group-1000001701.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -3; /* Align the icon to the left of the padding area */
    top: 8; /* Adjust if necessary for alignment */
    width: 25px; /* Icon width */
    height: 25px; /* Icon height */
  }

/* Hover effect */
.account-tableSettings th:not(:first-child):hover {
    background-image: url('/playground_assets/underlineDashboard.svg');
    background-size: cover 1px; /* Stretch to 100% width, 1px height */
    background-repeat: no-repeat;
    background-position: bottom left; /* Align the background to the bottom */
}
  .noiconthAccountListSettings {
 
    background-image: transparent !important;

  }
  
  .account-tableSettings td {
    color: #6C757D;
    font-size: 11px;
  }
  
  .account-tableSettings tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  /* Image styling */
  .account-tableSettings img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  
  /* Checkbox styling */
  .account-tableSettings th:first-child,
  .account-tableSettings td:first-child {
    text-align: center;
    padding-left: 16px;
  }
  
  .account-tableSettings th:first-child {
    padding-right: 0;
  }
  
  .account-tableSettings td:first-child {
    padding-right: 16px;
  }
  
  .account-tableSettings input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px; /* Adjust the margin as needed */
  }
  
  /* Sticky header for table */
  .account-tableSettings th {
    background-color: #f7f7f7;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  /* Adjustments for spacing and alignment */
  .account-tableSettings th, .account-tableSettings td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .FilterDivAccountList {
display: flex;
align-items: center;
gap: 8px;
width: 100%;
  }
  .filterIconList {
    width: 6px !important;
margin-left: -10px;

  }
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .widget-header button {
      padding: 8px;
      margin-left: 4px;
      font-size: 12px;
    }
  
    .account-tableSettings th, .account-tableSettings td {
      padding: 8px;
    }
  
    .account-tableSettings {
      font-size: 14px;
    }
  }
  