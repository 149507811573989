.data-wallet-card2 {
  flex: 0 0 auto;
  width: 500px;
  height: 70px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.domain-icon {
  margin-top: 7px;

}
.nuovacardwallet1 {
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.data-wallet-enterprise-results-containerAziende {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.data-wallet-enterprise-results-container {
  width: 100%;
  height: 100% !important;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.data-wallet-enterprise-results-results-wallet-data {
  width: 673px;
  height: 100% !important;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  cursor: pointer;
}
.data-wallet-enterprise-results-wallet-data-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  margin-top: 60px;
}
.data-wallet-enterprise-results-results-data-wallet-card {
  width: 773px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: column;
}
.data-wallet-enterprise-results-results-data-wallet-cardPortafoglio {
  height: 70px;
  width: 673px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;

  margin-bottom: 8px;
  justify-content: space-between;

  flex-direction: column;
}
.link{
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;

}
.title{
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
}
.data-wallet-enterprise-results-frame5064 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.aziende-enterprise-results{
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  width: 100%;
}
.data-wallet-enterprise-results-frame5063 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame5062 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame5061 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame5008 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame5003 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text02 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame5004 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text04 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame68 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin21 {
  width: 18px;
  height: 18px;
  position: relative;
}
.data-wallet-enterprise-results-frame5069 {
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.data-wallet-enterprise-results-frame50641 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50631 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame50621 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame50611 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text06 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50081 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50031 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50041 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text10 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame681 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin211 {
  width: 18px;
  height: 18px;
  position: relative;
}
.data-wallet-enterprise-results-frame50691 {
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.data-wallet-enterprise-results-frame50642 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50632 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame50622 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame50612 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text12 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50082 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50032 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text14 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50042 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text16 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame682 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin212 {
  width: 18px;
  height: 18px;
  position: relative;
}
.data-wallet-enterprise-results-frame50692 {
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.data-wallet-enterprise-results-frame50643 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50633 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame50623 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame50613 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text18 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50083 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50033 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50043 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text22 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame683 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin213 {
  width: 18px;
  height: 18px;
  position: relative;
}
.data-wallet-enterprise-results-frame50693 {
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.data-wallet-enterprise-results-frame50644 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50634 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame50624 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame50614 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text24 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50084 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50034 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text26 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50044 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text28 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame684 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin214 {
  width: 18px;
  height: 18px;
  position: relative;
}
.data-wallet-enterprise-results-frame50694 {
  width: 673px;
  display: flex;
  padding: 24px 20px;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.data-wallet-enterprise-results-frame50645 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50635 {
  display: flex;
  padding: 3px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 8px;
}
.data-wallet-enterprise-results-frame50625 {
  width: 16px;
  height: 16px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.data-wallet-enterprise-results-frame50615 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.data-wallet-enterprise-results-text30 {
  color: rgba(0, 33, 209, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 11px;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50085 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.data-wallet-enterprise-results-frame50035 {
  display: flex;
  padding: 6px 20px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(241, 241, 241, 1);
}
.data-wallet-enterprise-results-text32 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame50045 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.data-wallet-enterprise-results-text34 {
  color: rgba(107, 107, 107, 1);
  height: auto;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.data-wallet-enterprise-results-frame685 {
  display: flex;
  padding: 7px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 7px;
}
.data-wallet-enterprise-results-trashdeletebin215 {
  width: 18px;
  height: 18px;
  position: relative;
}

.aziende-city{
  width: 200px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.data-wallet-floating {
  display: none;
}
@media screen and (max-width: 702px) {
  .data-wallet-enterprise-results-wallet-data-container {
  
    margin-top: 35px;
  }
  .data-wallet-enterprise-header-filtri-e-scritta-wallet-enterprise {
    margin-top: -50px;
  }
  .data-wallet-floating {
    cursor: pointer;
    display: flex;
    padding: 14px 58px;
    position: fixed;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
    align-items: center;
    border-color: transparent;
    border-radius: 7px;
    background-color: rgba(255, 153, 0, 1);
    z-index: 998;
  }
  
  .data-wallet-enterprise-results-container {
    width: 323px;
    height: 711px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -50px;
  }
  .data-wallet-enterprise-results-containerAziende {
    width: 323px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -50px;
  }
  .data-wallet-enterprise-results-results-wallet-data {
    width: 343px;
    height: 1500px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    cursor: pointer;
  }
  .data-wallet-enterprise-results-results-data-wallet-cardPortafoglio {
    height: 100px;
    width: 343px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;



    margin-bottom: 8px;
    justify-content: space-between;

    flex-direction: column;
   
  }
  .data-wallet-title {
    font-size: 16px;
    text-overflow: ellipsis;
    display: none;
  }
  .data-wallet-enterprise-results-frame5008 {
    flex-direction: column;
  }
  .data-wallet-enterprise-results-frame5004 {
    margin-left: -30px;
    margin-top: 6px;
  }
  .data-wallet-card {
    width: 223px;
  }
  .data-wallet-enterprise-results-frame5064 {
    margin-top: 7px;
  }
  .news-search-result-list-link-news {
    font-size: 16px;
    margin-bottom: 25px;
    color: blue;
  }
  .data-wallet-enterprise-header-container {
    margin-right: -300px;
    margin-top: 40px;
  }
  .data-wallet-enterprise-header-text02 {
    display: none;
  }
.data-wallet-enterprise-header-text04 {
  font-size: 16px;
  width: 290px;
}
.data-wallet-enterprise-header-frame5017 {
  display: none;
}

.data-wallet-enterprise-header-frame5019 {
margin-left: -69px;
top: 172.44921875px;
}


}