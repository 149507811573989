.addAccountWidgetContainer {
    display: flex;
    flex-direction: column;
    background: #FF9900;
   width: 160px;
box-shadow: 0px 4px 4px 0px #00000040;
border-radius: 6px;
justify-content: center;
align-items: center;
font-family: "Encode Sans Expanded", sans-serif;
font-weight: 100;
font-style: normal;
color: #fff;
text-align: center;
padding: 30 22 30 22;
gap: 5px;
height: 129px;
cursor: pointer;

}
.buttonWidgetAddAccount {
    background-color: transparent;

}

.addAccountWidgetText {
    font-weight: 400;
    font-size: 14px;
}





.dashboardsettings .dashboardSettingsPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .dashboardsettings .dashboardSettingsPopupContent {
    background-color: #fff;
    padding: 20px;
    width: 622px;
    height: 875px;
  display: flex;
    border-radius: 6px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Encode Sans Expanded";
  }
  
  .dashboardsettings  h2 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #063565;
  font-family: "Encode Sans Expanded";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
  }
  .dashboardsettings h4 {
    color: #787777;
    text-align: center;
    font-family: "Encode Sans Expanded";
    font-size: 14px;
    margin-bottom: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  
  }
  .dashboardsettings .dashboardSettingsTicketForm {
    display: flex;
    flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  }
  
  .dashboardsettings .dashboardSettingsFormGroup {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  align-items: left;
  justify-content: center;
  }
  
  .dashboardsettings .dashboardSettingsFormLabel {
    font-weight: bold;
    margin-bottom: 10px;
    color: #787777;
  font-family: "Encode Sans Expanded";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  }
  
  .dashboardsettings .dashboardSettingsFormInput,
  .dashboardsettings .dashboardSettingsFormSelect,
  .dashboardsettings .dashboardSettingsFormTextarea {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #B1B1B1;
    
    background: #fbfafb00;
    width: 386px;
  height: 51px;
    font-size: 14px;
  }
  
  .dashboardsettings .dashboardSettingsFormTextarea {
    resize: vertical;
    min-height: 100px;
    width: 386px;
  height: 279px;
  }
  
  .dashboardsettings .dashboardSettingsFormActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .dashboardsettings .dashboardSettingsSubmitButton,
  .dashboardsettings .dashboardSettingsCancelButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dashboardsettings .dashboardSettingsSubmitButton {
    border-radius: 6px;
    background: #F90;
    color: #fff;
    margin-left: 10px;
    width: 151px;
  height: 52.5px;
  }
  
  .dashboardsettings .dashboardSettingsCancelButton {
    background-color: #ccc;
    color: #000;
  }