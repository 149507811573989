.manage-accounts-popup {
    display: flex;
    justify-content: space-around;
    background-color: #FBFAFB; /* or any color you prefer */

 box-shadow: 0px 4px 4px 0px #00000040;
width: 343px;
    border-radius: 6px; /* optional */
    align-self: left;
    height: 54px;
    font-family: "Encode Sans Expanded";
margin-bottom: 50px;
  }
  
  .popup-button {
    border: none;
    background-color: transparent; /* Light grey background */
    color: #063565; /* Dark text color */
    text-align: center;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
    font-weight: 400;
    display: inline-block;
    font-size: 9px;
  

   
 
    white-space: nowrap;
    width: 100px;
    height: 100%;
  }
  
  .popupAccountManagementdiv {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-left: 1px solid #00000012 ;
  }
  .popupAccountManagementdiv:first-child {

    border-left: 1px solid transparent ;
  }

.popupAccountManagementicon {
    width: 29px;
    margin-top: 5px;
}
.popupAccountManagementicon2 {
    width: 29px;
}
.popupAccountManagementdiv.disabled,
.popup-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}