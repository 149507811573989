:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.MessageResolution {
  display: flex;
  flex-direction: column;
 justify-content: center;
 align-items: center;
 align-content: center;
  font-family: "Encode Sans Expanded";
  font-weight: 600;
  margin-top: 200px;
 
 text-align: center;

}
.Resolution {
  font-size: 50px;

}
.Resolution2 {
  font-size: 37px;

  font-weight: 400;
  margin-top: 50px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #063565;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}



@media screen and (max-width: 1105px) {


  .side-bar-container::before {
    position: fixed; /* Fixed position */
    top: 0; /* Set the top to 0 */
    left: 0; /* Set the left to 0 */
    height: 100%; /* 100% Full-screen height */
    width: 200px; /* Cover 50% of the viewport width. You can set it as per your requirement */
    background: unset; /* Background color */
    box-shadow: none;
  }
.side-bar-container   {
  margin-top: 100px
}
.power-search-results-div {

}
.power-search-containerDataWallet {
 overflow: hidden
}
.data-wallet-enterprise-results-container {
  overflow: hidden
}
.data-wallet-enterprise-results-containerAziende{
  overflow: hidden;
}
}
@media screen and (max-width: 702px) {



.power-search-results-div {

}
}