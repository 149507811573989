.ReportsStatisticsContainer {
display: flex;
flex-direction: column;
gap: 15px;
margin-bottom: 45px;
}

.ReportsStatisticsContainerc1 {
    display: flex;
    gap: 15px;
}
.ReportsStatisticsContainerc1c {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.ReportsStatisticsContainerc2 {
    display: flex;
    gap: 15px;
}

.ReportsStatisticsContainerc3 {
    display: flex;
}
.ReportsStatisticsContainerc2c {
    display: flex;
    margin-top: -98px;
}