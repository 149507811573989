
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  .power-search-results-divChat {
    width: 70vw;
    min-height: 610px;
    display: flex;
    align-items: center;
   
    flex-direction: column;
    justify-content: flex-start;
  }
@media screen and (max-width: 710px) {
  .power-search-results-divChat {
    width: 100%;
    min-height: 610px;
    display: flex;
    align-items: center;
 
    flex-direction: column;
    justify-content: flex-start;
  }
}