.component-advanced-search-container {
  width: 618px;
  height: 1381px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.component-advanced-search-frame5139 {
  width: 100%;
  height: 1751px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-frame5115 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}
.component-advanced-search-frame5114 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 53px;
  flex-direction: column;
}
.component-advanced-search-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 8px;
  text-decoration: none;
}
.component-advanced-search-text02 {
  color: rgba(81, 81, 81, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5113 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-frame32 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
  flex-direction: column;
}
.component-advanced-search-frame33 {
  display: flex;
  padding: 0 8px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 8px;
}
.component-advanced-search-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame31 {
  width: 618px;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.component-advanced-search-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5112 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-frame5110 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 8px;
}
.component-advanced-search-rectangle56 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5111 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-rectangle57 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5122 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}
.component-advanced-search-text15 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}
.component-advanced-search-frame5121 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-frame5116 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle61 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text17 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5117 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle62 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text19 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5118 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle58 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text21 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5119 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle59 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text23 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5120 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-rectangle60 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-text25 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5132 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}
.component-advanced-search-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}
.component-advanced-search-frame5128 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 16px;
  flex-direction: column;
}
.component-advanced-search-frame5123 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle63 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 50px;
}
.component-advanced-search-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5124 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle64 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 50px;
}
.component-advanced-search-text31 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5125 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle65 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 50px;
}
.component-advanced-search-text33 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5126 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 15px;
}
.component-advanced-search-rectangle66 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 50px;
}
.component-advanced-search-text35 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5127 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-rectangle67 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 50px;
}
.component-advanced-search-text37 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5131 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.component-advanced-search-frame5129 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-right: 15px;
}
.component-advanced-search-text39 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  opacity: 0.50;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 8px;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame311 {
  display: flex;
  opacity: 0.50;
  padding: 6px 16px;
  position: relative;
  align-items: center;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.component-advanced-search-text41 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5130 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-text43 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  opacity: 0.50;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 8px;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame331 {
  display: flex;
  opacity: 0.50;
  padding: 6px 16px;
  position: relative;
  align-items: center;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  background-color: rgba(251, 251, 251, 1);
}
.component-advanced-search-text44 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5134 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}

.component-advanced-search-langauge {
  cursor: pointer;
}
.component-advanced-search-text46 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 13px;
  text-decoration: none;
}
.component-advanced-search-frame85 {
  display: flex;
  padding: 9px 291px 9px 11px;
  position: relative;
  align-items: flex-start;
  border-color: rgba(217, 217, 217, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  margin-bottom: 13px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.component-advanced-search-frame851 {
  display: flex;
  padding: 4px 11px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(243, 243, 243, 1);
}
.component-advanced-search-text48 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-group4 {
  width: 6px;
  height: 6px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-vector01 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-frame5133 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-text50 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text52 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text54 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text56 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text58 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5138 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}
.component-advanced-search-text60 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}
.component-advanced-search-frame5136 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-group85 {
  width: 395px;
  height: 46px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 8px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}
.component-advanced-search-frame852 {
  top: 9px;
  left: 11px;
  display: flex;
  padding: 4px 11px;
  position: absolute;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(243, 243, 243, 1);
}
.component-advanced-search-text62 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-group5 {
  width: 6px;
  height: 6px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector02 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-vector03 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-frame5135 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.component-advanced-search-text64 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text66 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text68 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text70 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.component-advanced-search-text72 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame5137 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 37px;
  flex-direction: column;
}
.component-advanced-search-text74 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 18px;
  text-decoration: none;
}
.component-advanced-search-frame34 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.component-advanced-search-frame14 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle561 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame21 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-google-g-logo1 {
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 4px;
}
.component-advanced-search-group {
  top: 0px;
  left: 0.17000067234039307px;
  width: 15.490076065063477px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector04 {
  top: 6.4733076095581055px;
  left: 7.830078125px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.component-advanced-search-vector05 {
  top: 9.526664733886719px;
  left: 0.8599994778633118px;
  width: 12px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-vector06 {
  top: 4.413333892822266px;
  left: 3.973642748178463e-8px;
  width: 4px;
  height: 7px;
  position: absolute;
}
.component-advanced-search-vector07 {
  top: 0px;
  left: 0.8599994778633118px;
  width: 12px;
  height: 6px;
  position: absolute;
}
.component-advanced-search-text76 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame15 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle571 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame22 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-duckduckgoseeklogocom1 {
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 4px;
}
.component-advanced-search-group1 {
  top: 0.002635293873026967px;
  left: 0.043937500566244125px;
  width: 15.929999351501465px;
  height: 15.992469787597656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector08 {
  top: 1.1795450448989868px;
  left: 1.1750000715255737px;
  width: 14px;
  height: 14px;
  position: absolute;
}
.component-advanced-search-vector09 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.component-advanced-search-group2 {
  top: 2.64864182472229px;
  left: 4.119520664215088px;
  width: 9.136805534362793px;
  height: 12.282055854797363px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector10 {
  top: 0.5213033556938171px;
  left: 0.3873484134674072px;
  width: 2px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-vector11 {
  top: 0.05021314322948456px;
  left: 0.41591665148735046px;
  width: 1px;
  height: 0px;
  position: absolute;
}
.component-advanced-search-vector12 {
  top: 1.4118446111679077px;
  left: 0px;
  width: 2px;
  height: 10px;
  position: absolute;
}
.component-advanced-search-vector13 {
  top: -1.559317013288819e-7px;
  left: 0.2742219567298889px;
  width: 6px;
  height: 12px;
  position: absolute;
}
.component-advanced-search-vector14 {
  top: 3.740189552307129px;
  left: 1.4148541688919067px;
  width: 1px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-vector15 {
  top: 3.9456799030303955px;
  left: 2.0103540420532227px;
  width: 0px;
  height: 0px;
  position: absolute;
}
.component-advanced-search-vector16 {
  top: 3.512424945831299px;
  left: 4.845916748046875px;
  width: 1px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-vector17 {
  top: 3.689052104949951px;
  left: 5.358041763305664px;
  width: 0px;
  height: 0px;
  position: absolute;
}
.component-advanced-search-vector18 {
  top: 2.588994026184082px;
  left: 0.9232849478721619px;
  width: 1px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-vector19 {
  top: 2.413404941558838px;
  left: 4.501541614532471px;
  width: 1px;
  height: 0px;
  position: absolute;
}
.component-advanced-search-vector20 {
  top: 4.5221943855285645px;
  left: 3.4475862979888916px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.component-advanced-search-group3 {
  top: 11.903223991394043px;
  left: 6.266021728515625px;
  width: 4.440042972564697px;
  height: 2.582472324371338px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector21 {
  top: 0.28148478269577026px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.component-advanced-search-vector22 {
  top: -3.7752073467345326e-7px;
  left: 2.7684781551361084px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.component-advanced-search-vector23 {
  top: 0.9279597401618958px;
  left: 1.444964051246643px;
  width: 1px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-vector24 {
  top: 0.6152690649032593px;
  left: 1.5637890100479126px;
  width: 1px;
  height: 1px;
  position: absolute;
}
.component-advanced-search-text78 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame16 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle581 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame23 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-group4 {
  width: 11px;
  height: 16px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 4px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector25 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 16px;
  position: absolute;
}
.component-advanced-search-text80 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame17 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle591 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame24 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-layer1 {
  width: 14px;
  height: 16px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 4px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-path1139 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 16px;
  position: absolute;
}
.component-advanced-search-text82 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame18 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle601 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame231 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-g2225 {
  width: 15px;
  height: 16px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 4px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-path2227 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 16px;
  position: absolute;
}
.component-advanced-search-path2229 {
  top: 9.070693016052246px;
  left: 2.889000654220581px;
  width: 4px;
  height: 5px;
  position: absolute;
}
.component-advanced-search-path2231 {
  top: 10.664529800415039px;
  left: 7.338585376739502px;
  width: 4px;
  height: 4px;
  position: absolute;
}
.component-advanced-search-text84 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame19 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 16px;
}
.component-advanced-search-rectangle602 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame232 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-group5 {
  width: 11px;
  height: 16px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 4px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.component-advanced-search-vector26 {
  top: 13.538799285888672px;
  left: 0.1612972766160965px;
  width: 11px;
  height: 2px;
  position: absolute;
}
.component-advanced-search-vector27 {
  top: -0.000017503001799923368px;
  left: 0px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.component-advanced-search-text86 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame20 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-rectangle603 {
  width: 16px;
  height: 16px;
  position: relative;
  border-color: rgba(161, 161, 161, 1);
  border-style: solid;
  border-width: 1px;
  margin-right: 8px;
  border-radius: 4px;
}
.component-advanced-search-frame233 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.component-advanced-search-ecosiaicon1 {
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 4px;
}
.component-advanced-search-text88 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.component-advanced-search-frame13 {
  display: flex;
  padding: 10px 14px;
  position: relative;
  align-items: center;
  border-color: transparent;
  border-radius: 7px;
  background-color: rgba(255, 153, 0, 1);
  cursor: pointer;
  margin-bottom: 100px;
}
.component-advanced-search-text90 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: "Encode Sans Expanded";
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.search-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: transparent;
  width: auto;
  height: 100%;
}

.search-input:focus {
  outline: none;
}
